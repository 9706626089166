<div *ngIf="showSigninForm">
  <form #newSigninForm="ngForm" (ngSubmit)="onSubmit(newSigninForm)" novalidate>
    <div class="form-floating">
      <input required 
            class="stl-input form-control"
            type="text"
            #contractorName="ngModel"
            [class.is-invalid]="contractorName.invalid && contractorName.touched"
            [(ngModel)]="newSignin.contractorName"
            name="contractorName"
            id="'name"
            placeholder="Name">
      <label for="name">Name</label>
      <small [class.hide-me]="contractorName.valid || contractorName.untouched">Name cannot be empty</small>
    </div>
    <div class="form-floating">
      <input required 
            class="stl-input form-control"
            type="text"
            #company="ngModel"
            [class.is-invalid]="company.invalid && company.touched"
            [(ngModel)]="newSignin.company"
            name="company"
            id="'company"
            placeholder="Company">
      <label for="company">Company</label>
      <small [class.hide-me]="company.valid || company.untouched">Manufacturer cannot be empty</small>
    </div>     
    <div class="form-floating">
      <input required
            class="stl-input form-control"
            type="text"
            #phone="ngModel"
            [class.is-invalid]="phone.invalid && phone.touched"
            [(ngModel)]="newSignin.phone"
            name="phone"
            id="'phone"
            placeholder="Phone">
      <label for="phone">Phone</label>
      <small [class.hide-me]="phone.valid || phone.untouched">Description cannot be empty</small>
    </div>
    <div class="form-floating">
      <input
            class="stl-input form-control"
            type="text"
            #serviceNumber="ngModel"
            [class.is-invalid]="serviceNumber.invalid && serviceNumber.touched"
            [(ngModel)]="newSignin.serviceNumber"
            name="serviceNumber"
            id="serviceNumber"
            placeholder="Service Number">
      <label for="serviceNumber">Service Number</label>
    </div>
    <!--span>{{newSignin.site}}</span-->
    <button type="submit" class="btn btn-secondary">Signin</button>
    <div *ngIf="signinSuccessDetails">
      {{signinSuccessDetails.contractorName}} sucessfully signed in to DFE {{signinSuccessDetails.site}} at {{ signinSuccessDetails.time | date : 'EEEE, MMMM d, h:mm:ss' }}. 
    </div>
  </form>
</div>
