import { AssetService } from '../../../services/assets.service';
import { Component, OnInit,ViewChild,Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder,Validators } from '@angular/forms';
import { Asset,AssetProperty,AssetListResult } from '../../models/Asset'
import { ProductSearchComponent } from '../../product-search/product-search.component';
import { MatDialog } from '@angular/material/dialog';
import { assetPropertyNewPopupComponent } from 'app/popups/assetPropertyNewPopup/assetpropertyNewPopup.component';
import { UserService } from 'services/user.service';
import { get } from 'http';
import { assignAssetToUnitPopupComponent } from 'app/popups/AssignAssetToUnitPopup/assignAssetToUnitPopup.component';
import { AssetUnitService } from 'services/assets.units.service';

enum AssetDetailsState{
  Loading,
  Displaying,
  Error
}

@Component({
  selector: 'asset-details',
  templateUrl: './asset-details.component.html',
  styleUrls: ['./asset-details.component.css']
})

export class AssetDetailsComponent implements OnInit {

  pageState: AssetDetailsState = AssetDetailsState.Loading;
  public tag: string;
  public asset: Asset;
  public properies: Array<AssetProperty> = [];
  showAddAssetForm = false;
  public pageLinks: Array<number> = []

  constructor(
    private _assetService: AssetService,
    private _assetUnitService: AssetUnitService,
    private _user: UserService,
    public dialogProperty: MatDialog,
    private route: ActivatedRoute,
    public dialogAssignAsset: MatDialog
  ){
    this.asset = new Asset(); //Empty asset to prevent errors when rendering HTML
  }

  ngOnInit() {
    this.tag = this.route.snapshot.paramMap.get('tag');
    this.getAsset();
  }

  getAsset(){
    this.pageState = AssetDetailsState.Loading;
    this._assetService.getAsset(this.tag).subscribe( _asset => {
      this.asset = _asset
      this.pageState = AssetDetailsState.Displaying;
      this.getProperties();
    });
  }

  getProperties(){
      this._assetService.getAsseteProperties(this.tag).subscribe( _properties => {
        this.properies = _properties
      });
  }

  openAssetPropertyNewPopup(property:string='') {
    const dialogRef = this.dialogProperty.open(assetPropertyNewPopupComponent, {
      data: {asset: this.asset.tag, property: property, value: ''}
    });

    dialogRef.afterClosed().subscribe(result => {

      if(result != undefined){
        this._assetService.setAssetProperty(result.asset, result.property, result.value).subscribe( _unitAssets => {
          this.getProperties();
        });
      }
    });
  }

  setProperty(property: string){
    if(this.canUpdateProperty){
      this.openAssetPropertyNewPopup(property);
    }
  }

  deleteProperty(property: string){
    if(this.canDeleteProperty){
      this._assetService.deleteAssetProperty(this.asset.tag, property).subscribe( _unitAssets => {
        this.getProperties();
      });
    }
  }

  openAssignAssetPopup() {
    const dialogRef = this.dialogAssignAsset.open(assignAssetToUnitPopupComponent, {
      data: {unit: '', asset: this.asset.tag}
    });

    dialogRef.afterClosed().subscribe(result => {

      if(result != undefined){
        this._assetUnitService.assignAsset(result, this.asset.tag).subscribe( _unitAssets => {
          this.getAsset();
        });
      }
    });
  }

  get canDeleteProperty(){
    return this._user.hasGroup("Engineering")
  }

  get canUpdateProperty(){
    return this._user.hasGroup("Engineering")
  }

  get isLoading(){ return this.pageState == AssetDetailsState.Loading;} 
  get isError(){ return this.pageState == AssetDetailsState.Error; }
  get isDisplaying(){ return this.pageState == AssetDetailsState.Displaying; }
}

