<form #newPurchaseForm="ngForm" (ngSubmit)="onSubmit(newPurchaseForm)" novalidate>
    <div>Name 
      <input required 
             class="stl-input"
             type="text"
             #name="ngModel"
             [class.is-invalid]="name.invalid && name.touched"
             [(ngModel)]="newPurchase.name"
             name="name">
      <small [class.hide-me]="name.valid || name.untouched">Name cannot be empty</small>
    </div>
    <div>Supplier
      <input required 
             class="stl-input"
             type="text"
             #supplier="ngModel"
             [class.is-invalid]="supplier.invalid && supplier.touched"
             [(ngModel)]="newPurchase.supplier"
             name="supplier">
      <small [class.hide-me]="supplier.valid || supplier.untouched">Supplier cannot be empty</small>
    </div>
    <div>Creator
      <input required
             class="stl-input"
             type="text"
             #creator="ngModel"
             [class.is-invalid]="creator.invalid && creator.touched"
             [(ngModel)]="newPurchase.creator"
             name="creator">
      <small [class.hide-me]="creator.valid || creator.untouched">Description cannot be empty</small>
    </div>
    <button>Add</button>
  </form>
