import { AssetService } from '../../services/assets.service';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { PurchaseListResult } from '../models/PurchaseListResult'
import { Purchase } from '../models/Purchase'

@Component({
  selector: 'purchase-list',
  templateUrl: './purchase-list.component.html',
  styleUrls: ['./purchase-list.component.css']
})

export class PurchaseListComponent implements OnInit {

  public purchases: Array<Purchase> = [];
  public pageLinks: Array<number> = []
  
  public search: string = "";
  public sort: string = "";
  public page: number = 1;

  public filterForm: UntypedFormGroup;

  public showAddPurchaseForm: boolean = false;

  constructor(
    private _assetService: AssetService,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if('search' in params){
        this.search = params.search;
      }
      if('sort' in params){
        this.sort = params.sort;
      }
      if('page' in params){
        this.page = params.page;
      }
    })

    this.loadPurchases(); 
  }

  loadPurchases() {
    this._assetService.getPurchases(this.search, this.sort, this.page).subscribe((data: PurchaseListResult) => {
      this.purchases = data.purchases;

      this.pageLinks = [];
      for(let i = 0; i< data.totalPages; i++){
        this.pageLinks.push(i);
      }

      this.updateRoute();
    })
  }

  updateOnNewPurchase(){
    this.search = "";
    this.sort="latest";

    this.loadPurchases();
  }

  updateRoute( ){
    var queryParams = {};
    if (this.search != "" ){queryParams['search'] = this.search;}
    if (this.sort != "" ){queryParams['sort'] = this.sort;}
    if (this.page > 1){queryParams['page']=this.page;}
    this.router.navigate(['/purchases'], {queryParams: queryParams});
  }

  navigatePage(page:number){
    this.page = page;
    this.loadPurchases();
  }

  setShowAddPurchaseForm(value: boolean){
    this.showAddPurchaseForm = true;
  }
  setHideAddPurchaseForm(value: boolean){
    this.showAddPurchaseForm = false;
  }
}

