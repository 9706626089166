import { AssetService } from '../../services/assets.service';
import { Component, OnInit, Output,EventEmitter } from '@angular/core';

import {
  NgForm,
  Validators,
  FormControl,
  FormGroupDirective,
} from '@angular/forms';

import { NewProduct } from '../models/NewProduct';


@Component({
  selector: 'new-product-form',
  templateUrl: './new-product-form.component.html',
  styleUrls: ['./new-product-form.component.css']
})
export class NewProductFormComponent implements OnInit {

  newProduct = new NewProduct("","","",false,false,"");
  public manufacturers: any = [];
  @Output() onNewItem: EventEmitter<any> = new EventEmitter();

  skuFormControl = new FormControl('', [Validators.required]);

  constructor(
    private _assetService: AssetService
  ) { }

  ngOnInit() {
    this._assetService.getManufacturers().subscribe(data => {
      this.manufacturers = data;
    })
  }

  onSubmit(newProductForm: NgForm){
    this._assetService.addProduct(this.newProduct)
      .subscribe(data => {
        newProductForm.reset();
        this.newProduct = new NewProduct("","","",false,false,"");
        this.onNewItem.emit(null);
      });
  }

  

}
