import {AssetService} from '../../../services/assets.service';
import { Component, OnInit, Input } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Product } from '../../models/Product';
import { ServiceRequest } from 'app/models/ServiceRequest';

@Component({
  selector: 'serviceRequest-item',
  templateUrl: './serviceRequest-item.component.html',
  styleUrls: ['./serviceRequest-item.component.css']
})
export class ServiceRequestItemComponent implements OnInit {

  @Input() serviceRequest: ServiceRequest;

  closeButtonText: string = "Close";

  constructor(private _assetService: AssetService) { }

  ngOnInit() {

  }

  closeServiceRequest(){
    this.closeButtonText = "Closing";
    this._assetService.setServiceRequestState(this.serviceRequest.number, "closed").subscribe(
      responce => {
          window.location.reload();
      },
      error => {
        this.closeButtonText = "Error";
      }
    );
  }
}
