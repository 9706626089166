import {AssetService} from '../../services/assets.service';
import { Component, OnInit, Output,EventEmitter, Input } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NewProduct } from '../models/NewProduct';
import { ContractorSignin, newContractorSignin } from '../models/ContractorSignin';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'new-signin-form',
  templateUrl: './new-signin-form.component.html',
  styleUrls: ['./new-signin-form.component.css']
})
export class NewSigninFormComponent implements OnInit {

  newSignin = new newContractorSignin();
  @Input() 
  set site(_site: string){
    this.newSignin.site = _site;
  }


  @Output() onNewSignin: EventEmitter<any> = new EventEmitter();
  showSigninForm: boolean =  true;
  showSigninSucess: boolean = false;
  signinSuccessDetails: ContractorSignin;

  constructor(
    private _assetService: AssetService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {

    this.newSignin.site = this.site;

  }

  onSubmit(newSigninForm: NgForm){
    this._assetService.addSignin(this.newSignin)
      .subscribe(data => {
        this.signinSuccessDetails = data;

        this.newSignin = new newContractorSignin();
        this.newSignin.site = data.site;
        newSigninForm.reset();
        this.onNewSignin.emit(null);
      });
  }

}
