<h1>Contractor Signin</h1>
<new-signin-form (onNewSignin)=updateOnNewSignin() > </new-signin-form>
<div *ngIf="loginDisplay" style="width: 600em">
  <H2>History</H2>

  <div>
    <table style="width:60em">
      <tr>
        <th> Timestamp</th>
        <th> Name</th>
        <th> Company</th>
        <th> Phone</th>
        <th> Site</th>
        <th> Service Number</th>
      </tr>
      <tr *ngFor="let p of signins" class="table-row">
        <td>{{ p.time+'Z' | date : 'EEEE, MMMM d, h:mm:ss' }}</td>
        <td>{{p.contractorName}}</td>
        <td>{{p.company}}</td>
        <td>{{p.phone}}</td>
        <td>{{p.site}}</td>
        <td>{{p.serviceNumber}}</td>
          
      </tr>
    </table>
  </div>
  
  <div class="page-bar">
    <a class="pagination" *ngFor="let p of pageLinks" (click)="navigatePage(p+1)">{{p+1}}</a>
  </div>

  <form [formGroup]="filterForm">
    Filter By Site
    <select class="dropdown" forControlName="site" (change)="onSiteChange($event.target.value)">
      <option value=""><div class="dropdown-item">All</div></option>
      <option value="Warwick Farm">Warwick Farm</option>
      <option value="Kemps Creek">Kemps Creek</option>
      <option value="Campbellfield">Campbellfield</option>
      <option value="Dandenong">Dandenong</option>
      <option value="Crestmead">Crestmead</option>
    </select>
  </form>

</div>