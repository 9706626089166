import { PurchaseItem } from './PurchaseItem';

export class Purchase{
    id: string;
    name: string;
    supplier: string;
    status: string;
    creator: string;
    timestamp: Date;
    purchaseItems: Array<PurchaseItem>;
}