export class Unit{
    unit: string;
    domain: string;
    parent: string;
    location: string;
    model: string;
    description: string;
}

export class QuickUnit{
    unit: string;
    location: string;
    code: number;
}

export class UnitServiceRequestList{
    
}

export class UnitListResult{
    units: Array<Unit>;
    totalPages: number;
    page: number;
    manufacturer: string;
    search: string;
    message: string;
}

export class UnitAsset{
    unit: string;
    asset: string;
    startDate: Date;
    startDateBy: string;
    endDate: Date;
    endDateBy: string;
}
export class UnitStock{
    unit: string;
    stock: string;
    quantity: number;
}

export class UnitStockUpdateDTO{
    stock: string;
    quantity: number;
}

export class UnitStockLog{
    id: string;
    unit: string;
    sku: string;
    action: string;
    value: number;
    actionBy: string;
    actionDate: Date;
}
