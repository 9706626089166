<div>
  <H2>Assets</H2>

  <div *ngIf="isLoading">
    <H2>Loading...</H2>
  </div>

  <div *ngIf="isDisplaying">
    <div class="keyword-wrapper">
      <input [formControl]="queryField" type="text" id="keyword" placeholder="Search for asset..." autofocus/>
    </div> 

    <mat-drawer-container>

      <mat-drawer #newPopup class="popup" #newPop mode="over" opened="false" [position]="pop_position">
        <button mat-flat-button (click)="newPop.close()" type="button" class="right"><<</button>
        <hr>
        <div *ngIf="isCaptureNew">
            <form [formGroup]="addAssetForm" (ngSubmit)="AddAsset()">
              Product: {{productSearch.selectedProduct.sku}}<br/>
              Serial Number: <input type="text" formControlName="newSerial" placeholder="123-456">
              <button type="submit"> Add </button>
            </form>
            <manufacturer-select #manufacturerSelect (OnManufacturerChangeCallback)="search.updateManufacturer($event[0])"></manufacturer-select>
            <product-search #productSearch [manufacturer]="manufacturerSelect.manufacturer"></product-search>
            <button (click)="setHideAddAssetForm()">done</button>
        </div>
        <div *ngIf="isCreatedNew">
          <H2>Asset Created</H2>
          <a routerLink="/assets/{{newAsset.tag}}">{{newAsset.tag}}</a>
          <br/>
          <button (click)="resetNewAssetForm()">Reset</button>
        </div>
        <div *ngIf="isErrorNew">
          <H2>Failed to Create...</H2>
          {{errorMessageNew}}<br/>
          <button (click)="resetNewAssetForm()">Reset</button>
        </div>
      </mat-drawer>

      <mat-drawer-content class="min-table-height">
        <button (click)="newPop.open()" mat-flat-button>+</button>
        <asset-item *ngFor="let a of assets" [asset]="a"></asset-item>
      </mat-drawer-content>
    </mat-drawer-container>  
    
    <nav>
      <ul class="pagination">
        <li class="page-item" *ngFor="let p of pageLinks" >
          <a class="page-link" (click)="navigatePage(p+1)">{{p+1}}</a>
        </li>
      </ul>
    </nav>  
  </div>

  <div *ngIf="isError">
    <H2>Failed to Load...</H2>
  </div>

</div>