<div *ngIf="loginDisplay" class="section-view">
    <p>Loading...</p>
</div>

<div *ngIf='isCapture' class="section-view">
    <h2>Create Service Request</h2>
    <form #newServiceRequestForm="ngForm" (ngSubmit)="onSubmit(newServiceRequestForm)" novalidate>
        Create Service Request for {{newNewServiceRequest.site}} - {{newNewServiceRequest.target}} As {{newNewServiceRequest.createdBy}}

        <div class="form-floating">
            <input required 
                    class="stl-input form-control"
                    type="text"
                    #title="ngModel"
                    [class.is-invalid]="title.invalid && title.touched"
                    [(ngModel)]="newNewServiceRequest.title"
                    name="title"
                    id="'title"
                    placeholder="Title">
            <label for="title">Title</label>
        </div>
        <small [class.hide-me]="title.valid || title.untouched">Title cannot be empty</small>

        <div class="form-floating">
            <textarea required
                class="stl-input form-control"
                type="text"
                #description="ngModel"
                [(ngModel)]="newNewServiceRequest.description"
                name="description"
                id="description"
                placeholder="Description"></textarea>
            <label for="description">Description</label>
        </div>
        <button  type="submit" class="btn btn-secondary">Create</button>
    </form>
</div>

<div *ngIf="isSubmitted" class="section-view">
    Service request submitted waiting for confrimation
</div>

<div *ngIf="isCreated" class="section-view">
    New service request created:
    <div class="full-width">
        <a class="sr-highlight" routerLink="/service/{{newServiceNumber}}" routerLinkActive='active'>{{newServiceNumber}}</a>
    </div> 
</div>

<div *ngIf="isError">
    <h1>Error</h1>
    <p>{{errorMessage}}</p>
</div>




