import { AssetService } from '../../../services/assets.service';
import { Component, OnInit, Input, Output,EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';


@Component({
  selector: 'manufacturer-select',
  templateUrl: './manufacturer-select.component.html',
  styleUrls: ['./manufacturer-select.component.css']
})

export class ManufacturerSelectComponent implements OnInit {

    public manufacturers: any = [];
    public manufacturer: string = "";
    public filterForm: UntypedFormGroup;
    @Output() OnManufacturerChangeCallback: EventEmitter<any> = new EventEmitter();

    constructor(
        private _assetService: AssetService,
        private formBuilder: UntypedFormBuilder) { }

    ngOnInit() {
        this._assetService.getManufacturers().subscribe(data => {
            this.manufacturers = data;
            this.filterForm.setValue({manufacturer: this.manufacturer});
        })

        this.filterForm = this.formBuilder.group({
            manufacturer: this.manufacturer
          })
    }

    onManufacturerChange(newValue){
        this.manufacturer = newValue;
        this.OnManufacturerChangeCallback.emit([newValue]);
      }
}
