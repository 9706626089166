<div>
  <H2>Units</H2>

  <div *ngIf="isLoading">
    <H2>Loading...</H2>
  </div>

  <div *ngIf="isDisplaying">
    <div class="keyword-wrapper">
      <input [formControl]="queryField" type="text" id="keyword" placeholder="Search for unit..." autofocus/>
    </div>


    <mat-drawer-container>

      <mat-drawer #newPopup class="popup" #newPop mode="over" opened="false" [position]="pop_position">
        <button mat-flat-button (click)="newPop.close()" type="button" class="right"><<</button>
        <hr>
        <div>
          <newUnitForm> </newUnitForm>
        </div>
      </mat-drawer>

      <mat-drawer-content class="min-table-height">
        <button (click)="newPop.open()" mat-flat-button>+</button>
        <unit-item *ngFor="let u of units" [unit]="u"></unit-item>
      </mat-drawer-content>
    </mat-drawer-container> 

    <nav>
      <ul class="pagination">
        <li class="page-item" *ngFor="let p of pageLinks">
          <a class="page-link"  (click)="navigatePage(p+1)">{{p+1}}</a>
        </li>
      </ul>
    </nav>
  </div>

  
  <div *ngIf="isError">
    <H2>Failed to Load...</H2>
  </div>

</div>