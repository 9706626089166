import { AssetService } from '../../../services/assets.service';
import { Component, OnInit,ViewChild,Output, ElementRef } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { ActivatedRoute } from '@angular/router';
import { ServiceRequestItemComponent } from 'app/elements/serviceRequest-item/serviceRequest-item.component';
import { ServiceRequestsListComponent } from 'app/elements/serviceRequest-list/serviceRequest-list.component';
import { UnitStockHistoryComponent } from 'app/elements/unitStockLog/unitStockLog.component';
import { Product } from 'app/models/Product';
import { Unit, UnitAsset, UnitStock } from 'app/models/Unit';
import { assignAssetPopupComponent } from 'app/popups/AssignAssetPopup/assignAssetPopup.component';
import { assignStockPopupComponent } from 'app/popups/AssignStockPopup/assignStockPopup.component';
import { AssetUnitService } from 'services/assets.units.service';
import { UserService } from 'services/user.service';

enum UnitDetailsState{
  Loading,
  Displaying,
  Error
}

@Component({
  selector: 'unit-details',
  templateUrl: './unit-details.component.html',
  styleUrls: ['./unit-details.component.css']
})

export class UnitDetailsComponent implements OnInit {

  pageState: UnitDetailsState = UnitDetailsState.Loading;
  public unit: Unit;
  public unitAssets: Array<UnitAsset> = [];
  public unitStock: Array<UnitStock> = [];
  public selectedSku: string = "";
  public selectedQuantity: number = 0;
  public selectedProduct: Product = new Product();

  @ViewChild(ServiceRequestsListComponent) serviceRequestList: ServiceRequestsListComponent;
  @ViewChild('stockPopup') stockPopup: MatSidenav;
  @ViewChild(('stockLog')) stockLog: UnitStockHistoryComponent

  showAddAssetForm = false;
  public pageLinks: Array<number> = []

  public pop_position: string = 'end';

  constructor(
    private _assetService: AssetService,
    private _assetUnitService: AssetUnitService,
    private _user: UserService,
    private route: ActivatedRoute,
    public dialogAsset: MatDialog,
    public dialogStock: MatDialog
  ){}

  ngOnInit() {
    this.getUnit();
  }

  getUnit() {
    var tag = this.route.snapshot.paramMap.get('unit');
    this._assetUnitService.getUnit(tag).subscribe( _unit => {
      this.unit = _unit
      this.pageState = UnitDetailsState.Displaying;
    });

    this._assetUnitService.getUnitAssets(tag).subscribe( _unitAssets => {
      this.unitAssets = _unitAssets;
    });
    this._assetUnitService.getUnitStock(tag).subscribe( _unitStock => {
      this.unitStock = _unitStock;
    });
  }

  refreshServiceRequestList() {
    this.serviceRequestList.loadServiceRequests();
  }

  openAssignAssetPopup() {
    const dialogRef = this.dialogAsset.open(assignAssetPopupComponent, {
      data: {unit: this.unit.unit, asset: ''}
    });

    dialogRef.afterClosed().subscribe(result => {

      if(result != undefined){
        this._assetUnitService.assignAsset(this.unit.unit, result).subscribe( _unitAssets => {
          this.getUnit();
        });
      }
    });
  }

  removeAsset( assignmentid: string){
    this._assetUnitService.removeAsset(this.unit.unit, assignmentid).subscribe( _unitAssets => {
      this.getUnit();
    });
  }

  openAssignStockPopup() {
    const dialogRef = this.dialogStock.open(assignStockPopupComponent, {
      data: {unit: this.unit.unit, stock: ''}
    });

    dialogRef.afterClosed().subscribe(result => {

      if(result != undefined){
        this._assetUnitService.addUnitStock(this.unit.unit, result).subscribe( _unitStock => {
          this.getUnit();
        });
      }
    });
  }

  setSelectedStock(stock){
    this.selectedSku = stock.sku;
    this.selectedQuantity = stock.quantity;
    this._assetService.getProduct(stock.sku).subscribe( _product => {
      this.selectedProduct = _product;
    });
    this.stockPopup.open();
    this.stockLog.loadServiceRequests();
  }

  updateStockQuantity(){
    this._assetUnitService.updateUnitStock(this.unit.unit, this.selectedSku, this.selectedQuantity).subscribe( _unitStock => {
      this._assetUnitService.getUnitStock(this.unit.unit).subscribe( _unitStock => {
        this.unitStock = _unitStock;
      });
    });
  }

    removeStock(stock: string){
    this._assetUnitService.deleteUnitStock(this.unit.unit, stock).subscribe( _unitStock => {
      this._assetUnitService.getUnitStock(this.unit.unit).subscribe( _unitStock => {
        this.unitStock = _unitStock;
      });
    });
    }

  get canUpdateStock(){
    return this._user.hasLocation(this.unit.location)
  }
  get canDeleteStock(){
    return this._user.hasRole("Maintenance.Write")
  }

  get isLoading(){ return this.pageState == UnitDetailsState.Loading;} 
  get isError(){ return this.pageState == UnitDetailsState.Error; }
  get isDisplaying(){ return this.pageState == UnitDetailsState.Displaying; }
}

