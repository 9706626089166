import { Product } from './Product';

export class Asset{
    tag: string;
    serial: string;
    productId: string;
    description: string;
    state: string;
    createdBy: string;
    createdDate: string;
}

export class AssetProperty{
    asset: string;
    property: string;
    value: string;
}

export class AssetListResult{
    assets: Array<Asset>;
    totalPages: number;
    page: number;
    manufacturer: string;
    search: string;
    message: string;
}
