import {AssetService} from '../../../services/assets.service';
import { Component, OnInit, Input } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Product } from '../../models/Product';
import { ServiceRequest } from 'app/models/ServiceRequest';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, observable } from 'rxjs';
import { UserService } from 'services/user.service';

@Component({
  selector: 'img-load',
  templateUrl: './img-load.component.html',
  styleUrls: ['./img-load.component.css']
})
export class ImgLoadComponent implements OnInit {

  public imageUrl: string | null = null;

  @Input() image: string;

  constructor(private _http: HttpClient, private _userService: UserService) { }

  ngOnInit() {
    this.loadImage();
  }

  loadImage() {
    if (this.image) {

      const options = {
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + this._userService.storageToken,
          'x-ms-version': '2023-08-03',
          'x-ms-date': new Date().toUTCString()
        }),
        responseType: 'blob' as 'json'
      };

      this._http.get<Blob>(this.image, options)
      .subscribe(blob_img => {
        this.imageUrl = URL.createObjectURL(blob_img);
      });
    }
  }
}

/*
When requesting an OAuth 2 token for Azure Blob Storage using RBAC (Role-Based Access Control), you need to specify the appropriate scope. Here’s how you can do it:

Register an Azure AD Application:
First, register an Azure AD application in your Azure portal.
Configure the necessary permissions for the application.
Assign an RBAC Role to the User:
Use the following command to assign the Storage Blob Data Contributor role to the user:
az role assignment create --role "Storage Blob Data Contributor" --assignee <email> --scope "/subscriptions/<subscription>/resourceGroups/<resource-group>/providers/Microsoft.Storage/storageAccounts/<storage-account>"

Replace <email> with the user’s email address and provide the correct subscription, resource group, and storage account details.
Get an OAuth 2.0 Token:
Obtain an OAuth 2.0 token by calling the authorization endpoint with the appropriate parameters:
GET https://login.microsoftonline.com/<tenant>/oauth2/v2.0/authorize?
client_id=<client_id>&
response_type=code&
redirect_uri=http://localhost:3000/&  # Specify your redirect URI
response_mode=query&
scope=https://storage.azure.com/user_impersonation&
state=12345

Replace <tenant> with your Azure AD tenant ID and <client_id> with your application’s client ID.
Exchange the Code for an Access Token:
Use the code obtained from the previous step to request an access token:
POST https://login.microsoftonline.com/<tenant>/oauth2/v2.0/token
Content-Type: application/x-www-form-urlencoded

client_id=<client_id>&
scope=https://storage.azure.com/user_impersonation&
code=<code>&
redirect_uri=http://localhost:3000/&  # Specify your redirect URI
grant_type=authorization_code&
client_secret=<client_secret>

Replace <code> with the code received and <client_secret> with your application’s secret.
Make API Requests:
Include the obtained access token in the Authorization header of your API requests to interact with Azure Blob Storage.
Remember to handle tokens securely and follow best practices for managing access to your storage resources! 🛡️🔒

Learn more*/