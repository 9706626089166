<div>
  <H2>Stock Update</H2>

  <div *ngIf="isLoading">
    <H2>Loading...</H2>
  </div>
  
  <div *ngIf="isDisplaying">
    <H3>{{sku}}</H3>
    <p>{{location}}</p>

    <mat-label>Quantity</mat-label>

    <div *ngIf="canUpdateStock">
      <mat-form-field>
          <input matInput [(ngModel)]="quantity" id="quantity" placeholder="Enter quantity">
      </mat-form-field> 
      <button mat-raised-button aria-label="update" (click)="newQuantity()">Update</button>
    </div>

    <div *ngIf="!canUpdateStock">
      <mat-label> {{selectedQuantity}} </mat-label>
    </div>

    <qr-code 
      value="https://portal.dfxpress.online/stock-update/{{location}}/{{sku}}"
      size="150"
      margin="6" >
    </qr-code>

    <h3>History</h3>
    <table>
      <tr>
        <th>By</th>
        <th>Date</th>
        <th>Action</th>
        <th>Quantity</th>
        <th>Total</th>
      </tr>
      <tr *ngFor="let transaction of history">
        <td>{{transaction.creator}}</td>
        <td>{{(transaction.timestamp+ 'Z').replace(' ', 'T') | dateTimeFormatFilter: 'MMMM Do YYYY, h:mm:ss a'}}</td>
        <td>{{transaction.action}}</td>
        <td>{{transaction.value}}</td>
        <td>{{transaction.quantity}}</td>
      </tr>
    </table>
  </div>

  <div *ngIf="isError">
    <H2>Failed to Load...</H2>
  </div>
</div>