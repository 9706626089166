import { NgModule } from '@angular/core';
import { MsalGuard } from '@azure/msal-angular';

import { Routes, RouterModule } from '@angular/router';
import { ProductListComponent } from './pages/product-list/product-list.component';
import { StockListComponent} from './pages/stock-list/stock-list.component';
import { StockListAllComponent} from './stock-list-all/stock-list-all.component';
import { PurchaseListComponent } from './purchase-list/purchase-list.component';
import { PurchaseDetailComponent } from './purchase-detail/purchase-detail.component'
import { AssetsPageComponent } from './pages/assets/assets-page.component';
import { AssetDetailsComponent } from './pages/asset-details/asset-details.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { SigninListComponent } from './pages/signin-list/signin-list.component';
import { ServiceRequestsPageComponent } from './pages/serviceRequests/serviceRequests-page.component';
import { QuickServicePageComponent } from './pages/quickService/quickService-page.component';
import { UnitsComponent } from './pages/units/units.component';
import { ServiceRequestsDetailsPageComponent } from './pages/serviceRequests-detail/serviceRequestsDetails-page.component';
import { UnitDetailsComponent } from './pages/unit-details/unit-details.component';
import { StockUpdateComponent } from './pages/stock-update/stock-update.component';

const routes: Routes = [
  {path: 'products', component: ProductListComponent, canActivate: [MsalGuard]},
  {path: 'purchases', component: PurchaseListComponent, canActivate: [MsalGuard]},
  {path: 'purchases/:id', component: PurchaseDetailComponent, canActivate: [MsalGuard]},
  {path: 'stock', component: StockListComponent, canActivate: [MsalGuard]},
  {path: 'stock-update/:location/:sku', component:StockUpdateComponent, canActivate: [MsalGuard]},
  //{path: 'stock/:site', component: StockListComponent, canActivate: [MsalGuard]},
  {path: 'assets', component: AssetsPageComponent, canActivate: [MsalGuard]},
  {path: 'assets/:tag', component: AssetDetailsComponent, canActivate: [MsalGuard]},
  {path: 'units', component: UnitsComponent, canActivate: [MsalGuard]},
  {path: 'units/:unit', component: UnitDetailsComponent, canActivate: [MsalGuard]},
  {path: 'profile', component: ProfileComponent, canActivate: [MsalGuard]},
  {path: 'signin', component: SigninListComponent},
  {path: 'signin/:site', component: SigninListComponent},
  {path: 'service', component:ServiceRequestsPageComponent, canActivate: [MsalGuard]},
  {path: 'service/:id', component:ServiceRequestsDetailsPageComponent, canActivate: [MsalGuard]},
  {path: 'quickservice/:tag', component:QuickServicePageComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
