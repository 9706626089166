import { NgModule } from '@angular/core';

import { BrowserModule } from  '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';

import { QrCodeModule } from 'ng-qrcode';

import {
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';


import { MatListModule} from '@angular/material/list';
import { MatGridListModule} from '@angular/material/grid-list';
import { MatTableModule } from '@angular/material/table';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCardModule } from '@angular/material/card';
import { MatSlideToggleModule} from '@angular/material/slide-toggle';
import {
  MatDialog,
  MatDialogRef,
  MatDialogTitle,
  MatDialogContent,
  MatDialogActions,
  MatDialogClose,
  MatDialogModule,
} from '@angular/material/dialog';

import {
  MsalGuard,
  MsalInterceptor,
  MsalModule,
  MsalRedirectComponent,
  MsalService
} from '@azure/msal-angular';

import { InteractionType, PublicClientApplication } from '@azure/msal-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { environment } from '../environments/environment'

import { AssetService } from '../services/assets.service';
import { UserService } from '../services/user.service';
import { DateTimeFormatPipe } from 'services/date.service';

import { ProductListComponent } from './pages/product-list/product-list.component';
import { StockListComponent } from './pages/stock-list/stock-list.component';
import { PurchaseListComponent } from './purchase-list/purchase-list.component'
import { PurchaseDetailComponent } from './purchase-detail/purchase-detail.component'
import { AssetsPageComponent} from './pages/assets/assets-page.component'
import { AssetDetailsComponent} from './pages/asset-details/asset-details.component'
import { StockListAllComponent } from './stock-list-all/stock-list-all.component';
import { NewProductFormComponent } from './new-product-form/new-product-form.component'
import { ProductItemComponent} from './elements/product-item/product-item.component'
import { NewPurchaseFormComponent } from './new-purchase-form/new-purchase-form.component'
import { ProductSearchComponent } from './product-search/product-search.component'
import { ManufacturerSelectComponent } from './elements/manufacturer-select/manufacturer-select.component'
import { ProfileComponent } from './pages/profile/profile.component';
import { SigninListComponent } from './pages/signin-list/signin-list.component';
import { NewSigninFormComponent } from './new-signin-form/new-signin-form.component';
import { NewServiceRequestFormComponent} from  './elements/newServiceRequest-form/newServiceRequest-form.component';
import { ServiceRequestItemComponent } from './elements/serviceRequest-item/serviceRequest-item.component';
import { ServiceRequestsPageComponent } from './pages/serviceRequests/serviceRequests-page.component';
import { ServiceRequestLogComponent } from './elements/serviceRequestLog/serviceRequestLog.component';
//import { NewServiceRequestFormComponent } from './new-servicerequest-form/new-servicerequest-form.component';
import { serviceRequestLogEntryFormComponent } from './elements/serviceRequestLogEntryForm/serviceRequestLogEntryForm.component'
import { AssetItemComponent } from './asset-item/asset-item.component';
import { QuickServicePageComponent } from './pages/quickService/quickService-page.component';
import { UnitsComponent } from './pages/units/units.component';
import { UnitItemComponent } from './elements/unit-item/unit-item.component';
import { UnitDetailsComponent } from './pages/unit-details/unit-details.component';
import { ServiceRequestLogItemComponent } from './elements/serviceRequestLog-item/serviceRequestLog-item.component';
import { ServiceRequestsListComponent } from './elements/serviceRequest-list/serviceRequest-list.component';
import { ServiceRequestsDetailsPageComponent } from './pages/serviceRequests-detail/serviceRequestsDetails-page.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ImgLoadComponent } from './utilities/serviceRequest-item/img-load.component';
import { newUnitFormComponent } from './elements/newUnitForm/newUnitForm.component';
import { assignAssetPopupComponent } from './popups/AssignAssetPopup/assignAssetPopup.component';
import { assignStockPopupComponent } from './popups/AssignStockPopup/assignStockPopup.component';
import { UnitStockHistoryComponent } from './elements/unitStockLog/unitStockLog.component';
import { assetPropertyNewPopupComponent } from './popups/assetPropertyNewPopup/assetpropertyNewPopup.component';
import { assignAssetToUnitPopupComponent } from './popups/AssignAssetToUnitPopup/assignAssetToUnitPopup.component';
import { AssetAssignemntsComponent } from './elements/asset-assignment/asset-assignments';
import { StockUpdateComponent } from './pages/stock-update/stock-update.component';
import { TransferListComponent } from './pages/transfers/transfer-list.component';

@NgModule({
  declarations:[
    AppComponent,
    ProductListComponent,
    ImgLoadComponent,
    TransferListComponent,
    StockListAllComponent,
    StockListComponent,
    StockUpdateComponent,
    NewProductFormComponent,
    NewPurchaseFormComponent,
    ProductItemComponent,
    PurchaseListComponent,
    PurchaseDetailComponent,
    ProductSearchComponent,
    ManufacturerSelectComponent,
    AssetsPageComponent,
    AssetItemComponent,
    AssetDetailsComponent,
    AssetAssignemntsComponent,
    UnitsComponent,
    UnitItemComponent,
    UnitDetailsComponent,
    newUnitFormComponent,
    ProfileComponent,
    SigninListComponent,
    NewSigninFormComponent,
    ServiceRequestsPageComponent,
    ServiceRequestsDetailsPageComponent,
    ServiceRequestsListComponent,
    ServiceRequestItemComponent,
    ServiceRequestLogComponent,
    serviceRequestLogEntryFormComponent,
    ServiceRequestLogItemComponent,
    NewServiceRequestFormComponent,
    QuickServicePageComponent,
    assignAssetPopupComponent,
    assignAssetToUnitPopupComponent,
    assignStockPopupComponent,
    assetPropertyNewPopupComponent,
    UnitStockHistoryComponent,
    DateTimeFormatPipe
  ],
  imports: [
    QrCodeModule,
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MatListModule,
    MatGridListModule,
    MatTableModule,
    MatSidenavModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatButtonModule,
    MatSelectModule,
    MatExpansionModule,
    MatCardModule,
    MatDialogModule,
    MatSlideToggleModule,
    MsalModule.forRoot( new PublicClientApplication({
      auth: {
        clientId: '19abf9f0-786d-4a68-8dd2-8129a254b0b5', // Application (client) ID from the app registration
        authority: 'https://login.microsoftonline.com/9d3d9817-0c3b-4c80-9666-a9948392f64a', // The Azure cloud instance and the app's sign-in audience (tenant ID, common, organizations, or consumers)
        redirectUri: environment.azureAuthRedurecUri // This is your redirect URI
      },
      cache: {
        cacheLocation: 'localStorage'
      },
    }),{
      interactionType: InteractionType.Redirect, // MSAL Guard Configuration
      authRequest: {
        scopes: ['user.read','user.read.all','GroupMember.Read.All'/*,'api://3bdabcdf-d76c-4823-8338-693862eb3656/access_api_as_user','https://storage.azure.com/user_impersonation'*/]
      }
    },{
      interactionType: InteractionType.Redirect, // MSAL Interceptor Configuration
      protectedResourceMap: new Map([ 
          ['https://graph.microsoft.com/v1.0/me', ['user.read']],
          ['https://graph.microsoft.com/v1.0/me/joinedTeams', ['user.read.all']],
          ['https://graph.microsoft.com/v1.0/me/transitiveMemberOf/microsoft.graph.group',['GroupMember.Read.All']],
          //['https://dfxpressstorage.blob.core.windows.net', ['https://storage.azure.com/user_impersonation']],
          [ environment.assetsAPIUrl + '/api/servicerequest/validate',null],
          [ environment.assetsAPIUrl + '/api/servicerequest/logevent',null],
          [ environment.assetsAPIUrl + '/api',['api://3bdabcdf-d76c-4823-8338-693862eb3656/access_api_as_user']]
      ])
    }),
    BrowserAnimationsModule
  ],
  providers: [
    AssetService,
    UserService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    DateTimeFormatPipe,
    MsalService,
    MsalGuard,
    MsalRedirectComponent 
  ],
  bootstrap: [AppComponent,MsalRedirectComponent]
})
export class AppModule { }
