<div>
  <div *ngIf="isLoading">
    <H2>Loading...</H2>
  </div>
  
  <div *ngIf="isDisplaying">
    <H1>{{serviceRequest.number}}</H1>

    <mat-card class="example-card">
      <mat-card-header>
        <mat-card-title-group>
          <mat-card-title>{{serviceRequest.title}} - <a routerLink="/units/{{serviceRequest.target}}" routerLinkActive='active'>{{serviceRequest.target}}</a></mat-card-title>
          <mat-card-subtitle>Current state: {{serviceRequest.state}}</mat-card-subtitle>
          <button *ngIf="serviceRequest.state=='open'" title="Closer Title" (click)="closeServiceRequest()" >{{closeButtonText}}</button>
        </mat-card-title-group>
      </mat-card-header>
      <mat-card-content>
        <small>Created by {{serviceRequest.createdBy}} on {{(serviceRequest.createdOn + 'Z').replace(' ', 'T') | date: 'medium'}}</small><br/>
        {{serviceRequest.description}}
      </mat-card-content>
    </mat-card>
    
    <button (click)="sidePop.open()" mat-flat-button>Add Event</button>

    <mat-drawer-container #listContainer class="min-height">

      <mat-drawer #editPopup class="popup" #sidePop mode="over" opened="false" [position]="pop_position">
        <button (click)="sidePop.close()" mat-button>>></button>
        <hr>

        <serviceRequestLogEntryForm
          [target]='serviceRequest.target'
          [serviceRequest]='serviceRequest.number'
          (onNewEvent)="refreshLogList()">
        </serviceRequestLogEntryForm>

      </mat-drawer>

      <mat-drawer-content>
        <serviceRequestLog [srNumber]='serviceRequest.number'></serviceRequestLog>
      </mat-drawer-content>

    </mat-drawer-container>


    
    
  </div>

  <div *ngIf="isError">
    <H2>Failed to Load...</H2>
  </div>

</div>