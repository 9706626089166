<div>
    <p><strong>First Name: </strong> {{profile?.givenName}}</p>
    <p><strong>Last Name: </strong> {{profile?.surname}}</p>
    <p><strong>Email: </strong> {{profile?.userPrincipalName}}</p>
    <p><strong>Id: </strong> {{profile?.id}}</p>
    <p><strong>Token:</strong>{{_userService.storageToken}}</p>
    <p><strong>Everything: </strong> {{everything}}</p>

    <p><strong>Roles: </strong> {{roles}}</p>
    <p><strong>Groups: </strong> {{_userService.Groups}}</p>

    <p><strong>Teams: </strong> {{teams}}</p>
</div>

