import { AssetService } from '../../../services/assets.service';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { ProductListResult } from '../../models/ProductListResult'
import { Product } from '../../models/Product'

enum ProductPageState{
  Loading,
  Displaying,
  Error
}

@Component({
  selector: 'product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.css']
})

export class ProductListComponent implements OnInit {

  private pageState: ProductPageState = ProductPageState.Loading;

  public products: Array<Product> = [];
  public manufacturers: any = [];
  public pageLinks: Array<number> = []
  
  public manufacturer: string = "";
  public search: string = "";
  public sort: string = "";
  public page: number = 1;

  public filterForm: UntypedFormGroup;

  public showAddProductForm: boolean = false;

  constructor(
    private _assetService: AssetService,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if('manufacturer' in params){
        this.manufacturer = params.manufacturer;
      }
      if('search' in params){
        this.search = params.search;
      }
      if('sort' in params){
        this.sort = params.sort;
      }
      if('page' in params){
        this.page = params.page;
      }
    })

    this.filterForm = this.formBuilder.group({
      manufacturer: this.manufacturer
    })
    //this.filterForm.setValue({manufacturer: this.manufacturer});

    //Populate the list of manufacturers
    this._assetService.getManufacturers().subscribe(data => {
      this.manufacturers = data;
      this.filterForm.setValue({manufacturer: this.manufacturer});
    })

    this.loadProducts(); 
  }

  loadProducts() {
    this._assetService.getProducts(this.manufacturer,this.search, this.sort, this.page)
      .subscribe((data: ProductListResult) => 
        {
          this.products = data.products;
          this.pageState = ProductPageState.Displaying;

          this.pageLinks = [];
          for(let i = 0; i< data.totalPages; i++){
            this.pageLinks.push(i);
          }

          this.updateRoute();
        },
        error => {
          this.pageState = ProductPageState.Error;
        }
    );
  }

  updateOnNewProduct(){
    this.manufacturer = "";
    this.search = "";
    this.sort="latest";

    this.loadProducts();
  }

  onManufacturerChange(newValue){
    this.manufacturer = newValue;
    this.page = 1;
    this.loadProducts();
  }

  updateRoute( ){
    var queryParams = {};
    if (this.manufacturer != "" ){queryParams['manufacturer'] = this.manufacturer;}
    if (this.search != "" ){queryParams['search'] = this.search;}
    if (this.sort != "" ){queryParams['sort'] = this.sort;}
    if (this.page > 1){queryParams['page']=this.page;}
    this.router.navigate(['/products'], {queryParams: queryParams});
  }

  navigatePage(page:number){
    this.page = page;
    this.loadProducts();
  }

  setShowAddProductForm(value: boolean){this.showAddProductForm = true;}
  setHideAddProductForm(value: boolean){this.showAddProductForm = false;}

  get isLoading(){ return this.pageState == ProductPageState.Loading;}
  get isDisplaying(){ return this.pageState == ProductPageState.Displaying;}
  get isError(){ return this.pageState == ProductPageState.Error;}
}

