import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgForm, UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Asset } from 'app/models/Asset';
import { NewServiceRequest } from 'app/models/ServiceRequest';
import { Unit } from 'app/models/Unit';
import { NewSigninFormComponent } from 'app/new-signin-form/new-signin-form.component';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs/internal/Subject';
import { debounceTime, distinctUntilChanged, filter, switchMap } from 'rxjs/operators';
import { AssetService } from 'services/assets.service';
import { UserService } from 'services/user.service';

enum ServiceRequestFormState {
  Loading,
  Capture,
  Submitted,
  Created,
  Error
}

@Component({
  selector: 'newServiceRequest-form',
  templateUrl: './newServiceRequest-form.component.html',
  styleUrls: ['./newServiceRequest-form.component.css']
})
export class NewServiceRequestFormComponent implements OnInit {

  public formState: ServiceRequestFormState = ServiceRequestFormState.Loading;

  @Input() public target: string;

  public loginDisplay = false;
  public newServiceNumber: string = '';
  public username: string = 'Guest';
  public signinSite = '';
  //public canCreateServiceRequest = false;
  public errorMessage = '';

  @Output() onNewServiceRequest: EventEmitter<any> = new EventEmitter();

  newNewServiceRequest = new NewServiceRequest("","",0,"","");

  get isLoading() { return this.formState == ServiceRequestFormState.Loading; }
  get isCapture() { return this.formState == ServiceRequestFormState.Capture; }
  get isSubmitted() { return this.formState == ServiceRequestFormState.Submitted; }
  get isCreated() { return this.formState == ServiceRequestFormState.Created; }
  get isError() { return this.formState == ServiceRequestFormState.Error; }

  constructor(
    private _assetService: AssetService,
    private _userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  //<todo> don't need to know the code at this point. Can skip the api call to get the code and get it on the back end.
  ngOnInit(): void {

    //this.canCreateServiceRequest = this._userService.hasRole('Supervisor.Write')

    if( this._userService.isLoggedIn() ){ 

      if (this._userService.hasRole('Supervisor.Write')){
        this.newNewServiceRequest.createdBy = this._userService.getUsername();
        this.newNewServiceRequest.code = 10;  // Need to get the site
        this.newNewServiceRequest.target = this.target;
        this.formState = ServiceRequestFormState.Capture;
      }
      else{
        this.formState = ServiceRequestFormState.Error;
        this.errorMessage = "You do not have permission to create a service request.";
      }
    }
    else{
      this.formState = ServiceRequestFormState.Error;
      this.errorMessage = "You must be logged in to create a service request.";
    }
  }

  onSubmit(newServiceRequestForm: NgForm){
    this._assetService.addServiceRequest(this.newNewServiceRequest)
    .subscribe(this.SubmitServiceRequest);
  }

  private SubmitServiceRequest = {
    next: (data) => {
      this.newServiceNumber = data['number'];
      this.formState = ServiceRequestFormState.Created;
      this.onNewServiceRequest.emit(null);
    },
    error: (error: any) => {
      this.formState = ServiceRequestFormState.Error;
      this.errorMessage = error;
    }
  }
}
