<form #newProductForm="ngForm" (ngSubmit)="onSubmit(newProductForm)" novalidate>
  <h1>Create new SKU</h1>

  <mat-form-field class="full-width">
    <mat-label>SKU</mat-label>
    <input matInput
           required 
           id="SkuInputField"
           placeholder="Enter SKU"
           type="text"
           #sku="ngModel"
           [(ngModel)]="newProduct.sku"
           name="sku">
    <mat-error>SKU cannot be empty</mat-error>
  </mat-form-field>
  
  <!--mat-form-field class="full-width">
    <mat-label>Manufacturer</mat-label>
    <input matInput 
           required
           id="manufacturerInputField"
           placeholder="Enter manufacturer" 
           type="text"
           #manufacturer="ngModel"
           [(ngModel)]="newProduct.manufacturer"
           name="manufacturer">
    <mat-error>SKU cannot be empty</mat-error>
  </mat-form-field-->

  <mat-form-field class="full-width">
    <mat-label>Manufacturer</mat-label>
    <mat-select [(ngModel)]="newProduct.manufacturer" name="manufacturer">
      <!--@for (food of foods; track food) {
        <mat-option [value]="food.value">{{food.viewValue}}</mat-option>
      }-->
      <mat-option [value]="m" *ngFor="let m of manufacturers">{{m}}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field class="full-width">
    <mat-label>Description</mat-label>
    <input matInput
           required
           id="descriptionInputField"
           placeholder="Enter description"
           type="text"
           #name="ngModel"
           [(ngModel)]="newProduct.description"
           name="description">
    <mat-error>Description cannot be empty</mat-error>
  </mat-form-field>

  <mat-checkbox [(ngModel)]="newProduct.is_stock"
                class="full-width"
                name="isStock">
    Is Stock
  </mat-checkbox>

  <mat-checkbox [(ngModel)]="newProduct.is_asset"
                class="full-width"
                name="isAsset">
    Is Asset
  </mat-checkbox>

  <mat-form-field class="full-width">
    <mat-label>Asset Class</mat-label>
    <input matInput
           id="assetClassInputField"
           placeholder="Enter asset class"
           type="text"
           #name="ngModel"
           [(ngModel)]="newProduct.assetClass"
           name="assetClass">
  </mat-form-field>

  <button mat-raised-button type="submit">Add</button>

</form>

  