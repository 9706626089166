import { AssetService } from '../../../services/assets.service';
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { QuantityAtLocationDTO, StockCountDTO, StockListResult, newStocktake } from '../../models/StockListResult';
import { Stocktake } from '../../models/Stocktake';
import { MatSidenav } from '@angular/material/sidenav';
import { UserService } from 'services/user.service';
import { debounceTime, distinctUntilChanged, filter, switchMap } from 'rxjs/operators';

enum StockUpdatePageState{
  Loading,
  Displaying,
  Error
}

@Component({
  selector: 'stock-update',
  templateUrl: './stock-update.component.html',
  styleUrls: ['./stock-update.component.css']
})

export class StockUpdateComponent implements OnInit{

  private pageState: StockUpdatePageState = StockUpdatePageState.Loading;
  
  public location: string = "";
  public sku: string = "";
  public quantity: number = 0;
  public history: Array<Stocktake>  = [];
  
  public updateMessage: string = "";

  constructor(
    private _assetService: AssetService,
    private _user: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder) { }

  ngOnInit() {
    this.location = this.route.snapshot.paramMap.get('location');
    this.sku = this.route.snapshot.paramMap.get('sku');
    this.loadQuantity();

  }

  loadQuantity(){
    this.pageState = StockUpdatePageState.Loading;
    this._assetService.getSkuQuantityAtLocation(this.location, this.sku).subscribe(this.readStock);
  }

  private readStock = {
    next: (data: QuantityAtLocationDTO) => {
      this.quantity = data.quantity;
      this.history = data.history;
      this.pageState = StockUpdatePageState.Displaying;
    },
    error: (e : any) => {
      this.pageState = StockUpdatePageState.Error;
    }
  }

  get isLoading(){ return this.pageState == StockUpdatePageState.Loading;}

  get isDisplaying(){ return this.pageState == StockUpdatePageState.Displaying;}

  get isError(){ return this.pageState == StockUpdatePageState.Error;}

  newQuantity(){

    var newStock = new newStocktake();
    newStock.sku = this.sku;
    newStock.location = this.location;
    newStock.quantity = this.quantity;
    newStock.action = "Set";
    this._assetService.updateStocktake(newStock).subscribe(this.updateStock);
  } 

  get canUpdateStock(){
    return this._user.hasLocation(this.location)
  }


  private updateStock = {
    next: (result: any) => {
      this.updateMessage = "Updated";
      this.loadQuantity();
    },
    error: (e : any) => {
      this.pageState = StockUpdatePageState.Error;
    }
  }
}
