<div *ngIf="isLoading">
  <H2>Loading...</H2>
</div>

<div *ngIf="isDisplaying">
  <div class="details-grid">
    <div class="details-grid-title">
      <H2>{{unit.unit}}</H2>
    </div>

    <div class="details-grid-details">
      Location: {{unit.location}} <br/>
      Model: {{unit.model}}
    </div>

    <div class="details-grid-qr">
      <qr-code 
          value="https://portal.dfxpress.online/quickservice/{{unit.unit}}"
          size="150"
          margin="6" 
          routerLink="/quickservice/{{unit.unit}}"
          routerLinkActive='active'
          class="clickable">
      </qr-code><br/>
      <!--a routerLink="/quickservice/{{unit.unit}}" routerLinkActive='active'>Service</a-->
    </div>
  </div>

  <div>
    <div class="flex-row">
      <div class="left-fill-spacer"><h1>Assigned Assets</h1></div>
      <button class="button-padding" (click)="openAssignAssetPopup()" mat-flat-button>+</button>
    </div>
    <ul>
      <li *ngFor="let asset of unitAssets" class="asset-list-grid">
        <div class="asset-list-grid-label">
          <a routerLink="/assets/{{asset.asset}}">{{asset.asset}}</a>
        </div>
        <div class="asset-list-grid-button">
          <button (click)="removeAsset(asset.id)" mat-flat-button>X</button>
        </div>
      </li>
    </ul>
  </div>

  <div>
    <div class="flex-row">
      <div class="left-fill-spacer"><h1>Assigned Stock</h1></div>
      <button class="button-padding" (click)="openAssignStockPopup()" mat-flat-button>+</button>
    </div>

    <mat-drawer-container #stockContainer>

      <mat-drawer class="popup" #stockPopup mode="over" opened="false" [position]="pop_position">
        <button mat-flat-button (click)="stockPopup.close()" type="button">>></button>
        <H3>{{selectedSku}}</H3>
        <div>{{selectedProduct.description}}</div>

        <div *ngIf="canUpdateStock">
          <mat-form-field>
              <input matInput [(ngModel)]="selectedQuantity" id="quantity" placeholder="Enter quantity">
          </mat-form-field> 
          <button mat-raised-button aria-label="update" (click)="updateStockQuantity()">Update</button>
          <unitstocklog #stockLog [unit]='unit.unit' [sku]='selectedSku'></unitstocklog>
        </div>

      </mat-drawer>
  
      <mat-drawer-content class="min-table-height">

        <ul>
          <li *ngFor="let stock of unitStock" class="stock-list-grid">
            <div class="stock-list-grid-label">
              {{stock.sku}}  
            </div>
            <div class="stock-list-grid-count dataElement" (click)='setSelectedStock(stock)'>{{stock.quantity}}</div>
            <div class="stock-list-grid-description"></div>
            <div class="stock-list-grid-bdelete" *ngIf="canDeleteStock">
              <button (click)="removeStock(stock.sku)" mat-flat-button>X</button>
            </div>
          </li>
        </ul>
        
      </mat-drawer-content>
  
    </mat-drawer-container> 


  </div>
  
  <div class="flex-row">
    <div class="left-fill-spacer"><h1>Service Requests</h1></div>
    <button class="button-padding" (click)="newPop.open()" mat-flat-button>+</button>
  </div>

  <mat-drawer-container #listContainer>

    <mat-drawer #newPopup class="popup" #newPop mode="over" opened="false" [position]="pop_position">
      <button mat-flat-button (click)="newPop.close()" type="button">>></button>
      <newServiceRequest-form [target]='unit.unit' (onNewServiceRequest)="refreshServiceRequestList()"></newServiceRequest-form>
    </mat-drawer>

    <mat-drawer-content class="min-table-height">
      <serviceRequest-list #serviceRequestList [tag]= 'unit.unit' ></serviceRequest-list>
    </mat-drawer-content>

  </mat-drawer-container> 

</div>

<div *ngIf="isError">
  <H2>Failed to Load...</H2>
</div>