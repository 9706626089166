import { AssetService } from '../../../services/assets.service';
import { Component, OnInit,ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder,Validators, UntypedFormControl } from '@angular/forms';
import { Asset,AssetProperty,AssetListResult } from '../../models/Asset'
import { ProductSearchComponent } from '../../product-search/product-search.component';
import { debounceTime, distinctUntilChanged, filter, switchMap } from 'rxjs/operators';
import { error } from 'console';

enum AssetPageState{
  Loading,
  Displaying,
  Error
}

enum NewAssetFormState{
  Capture,
  Created,
  Error
}

@Component({
  selector: 'assets-page',
  templateUrl: './assets-page.component.html',
  styleUrls: ['./assets-page.component.css']
})

export class AssetsPageComponent implements OnInit {

  public pageState: AssetPageState = AssetPageState.Loading;
  public newAssetFormState: NewAssetFormState = NewAssetFormState.Capture;

  public assets: Array<Asset> = [];
  public pageLinks: Array<number> = []
  
  public search: string = "";
  public sort: string = "";
  public page: number = 1;

  public filterForm: UntypedFormGroup;
  public queryField: UntypedFormControl = new UntypedFormControl();

  public showAddAssetForm: boolean = false;
  public addAssetForm: UntypedFormGroup = new UntypedFormGroup({});
  @ViewChild('productSearch') productSearch: ProductSearchComponent;

  public errorMessageNew: string = "";
  public newAsset: Asset = null;
  

  constructor(
    private _assetService: AssetService,
    private router: Router,
    private route: ActivatedRoute,
    private fb: UntypedFormBuilder){
      this.addAssetForm = fb.group({
        'newSerial' : ['', [Validators.required,Validators.maxLength(50)]]
      })
    }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if('search' in params){
        this.search = params.search;
      }
      if('sort' in params){
        this.sort = params.sort;
      }
      if('page' in params){
        this.page = params.page;
      }
    })

    this.loadAssets(); 

    const queryPipe = this.queryField.valueChanges
    .pipe(debounceTime(500))
    .pipe(distinctUntilChanged())

    // When the query field is changed update this.search and navigate to the enw route
    const apiCallPipe = queryPipe
      .subscribe(query => {
        this.search = query;
        this.loadAssets();
      });
  }

  loadAssets() {
    this._assetService.getAssets(this.search, this.sort, this.page,"").subscribe((data: AssetListResult) => {
        this.assets = data.assets;
        this.pageState = AssetPageState.Displaying;

        this.pageLinks = [];
        for(let i = 0; i< data.totalPages; i++){
          this.pageLinks.push(i);
        }

        this.updateRoute();
      },
        error => {
          this.pageState = AssetPageState.Error;
      }
    );
  }

  updateOnNewAsset(){
    this.search = "";
    this.sort="latest";

    this.loadAssets();
  }

  updateRoute( ){
    var queryParams = {};
    if (this.search != "" ){queryParams['search'] = this.search;}
    if (this.sort != "" ){queryParams['sort'] = this.sort;}
    if (this.page > 1){queryParams['page']=this.page;}
    this.router.navigate(['/assets'], {queryParams: queryParams});
  }

  navigatePage(page:number){
    this.page = page;
    this.loadAssets();
  }

  setShowAddAssetForm(value: boolean){
    this.showAddAssetForm = true;
  }
  setHideAddAssetForm(value: boolean){
    this.showAddAssetForm = false;
  }

  public AddAsset(){
    
    if(this.addAssetForm.valid){
      
      var newAssetSku:string = this.productSearch.selectedProduct.sku;
      var newAssetSerial: string = this.addAssetForm.get('newSerial').value;

      this._assetService.addAsset(newAssetSku, newAssetSerial).subscribe(this.NewAssetRequest)
    }
    else{
      console.log("Form is not valid")
    }
  }

  private NewAssetRequest = {
    next: (data: Asset) => {
      this.newAsset = data;
      this.newAssetFormState = NewAssetFormState.Created;
    },
    error: (error: any) => {
      this.errorMessageNew = error.error;
      this.newAssetFormState = NewAssetFormState.Error;
    }
  }

  resetNewAssetForm(){

    this.addAssetForm.reset();

    this.newAssetFormState = NewAssetFormState.Capture;
    this.newAsset = null;
    this.errorMessageNew = "";
  }

  get isLoading() { return this.pageState == AssetPageState.Loading; }
  get isDisplaying() { return this.pageState == AssetPageState.Displaying; }
  get isError() { return this.pageState == AssetPageState.Error; }

  get isCaptureNew() { return this.newAssetFormState == NewAssetFormState.Capture; }
  get isCreatedNew() { return this.newAssetFormState == NewAssetFormState.Created; }
  get isErrorNew() { return this.newAssetFormState == NewAssetFormState.Error; }
}

