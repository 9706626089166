<div>
<ul class="asset-assignment-container">
  <li *ngFor="let assignment of assetAssignments" >
    <div class="asset-assignment-grid">
      <div class="asset-assignment-grid-unit">
        {{assignment.unit}}
      </div>
      <div class="asset-assignment-grid-start">
        Assigned By {{assignment.startDateBy}} on {{assignment.startDate+"Z" | date:'MMM d, y, H:mm'}}
      </div>
      <div class = "asset-assignment-grid-end">
        <div *ngIf="assignment.endDate" class="asset-assignment-grid-value">
          Removed by {{assignment.endDateBy}} on {{assignment.endDate+"Z" | date:'MMM d, y, H:mm'}}
        </div>
        <div *ngIf="!assignment.endDate && canDeleteAssignment" class="asset-assignment-grid-delete">
          Remove <button (click)="removeAsset(assignment.unit, assignment.id)" mat-flat-button>X</button>
        </div>
      </div>
    </div>
</ul>
</div>

