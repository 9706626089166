<div class="container">
  <img class="image" src="assets/media/DefaultProductIcon.png">
  <div class="tag label">
      <a routerLink="/assets/{{asset.tag}}" routerLinkActive='active'>{{asset.tag}}</a>
  </div>
  <div class="description label">
      {{asset.productId}}
    </div>
  <div class="serial label">
    {{asset.serial}}
  </div>
</div>

