import {Component, Inject, OnInit} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {FormsModule, UntypedFormControl} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';

import { AssetService } from '../../../services/assets.service';
import { Asset } from 'app/models/Asset';

import { debounceTime,distinctUntilChanged,switchMap,filter, tap } from 'rxjs/operators'
import { Observable } from 'rxjs';

@Component({
  selector: 'assignAssetPopup',
  templateUrl: './assignAssetPopup.component.html',
  styleUrls: ['./assignAssetPopup.component.css']
})
export class assignAssetPopupComponent implements OnInit {

  public assets: Array<Asset> = [];
  public queryField: UntypedFormControl = new UntypedFormControl();

  public selectedAsset: Asset = null;

  constructor(
    private _assetService: AssetService,
    public dialogRef: MatDialogRef<assignAssetPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  ngOnInit() {
    const queryPipe = this.queryField.valueChanges
    .pipe(debounceTime(500))
    .pipe(distinctUntilChanged())

    const apiCallPipe = queryPipe
    .pipe(filter(query => query.length >= 3))
    .pipe(switchMap( query => this._assetService.getAssets(query,"",1,"available") ) )
    .subscribe((result: any) =>
      {
        this.assets = result.assets;
      });
    
    // If length is less than 4, clear assets list
    const clearPipe = queryPipe
    .pipe(filter(query => query.length < 3))
    .subscribe( query => this.assets = []);
  }

  onClickSelectAsset(asset: Asset){
    this.data.asset = asset.tag;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}

export interface DialogData {
  unit: string;
  asset: string;
}
