
<H1>{{serviceTarget}} - Quick Service</H1>

<div>
    <serviceRequestLogEntryForm [target]='serviceTarget'> </serviceRequestLogEntryForm>
</div>

<div>
    <newServiceRequest-form [target]='serviceTarget' (onNewServiceRequest)="refreshServiceRequestList()"></newServiceRequest-form>
</div>

<div *ngIf="loginDisplay" class="section-view">
    <h1>Open Service Requests</h1>
    <serviceRequest-list #serviceRequestList [tag]= 'serviceTarget' [state]= 'open'></serviceRequest-list>
</div>




