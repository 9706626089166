import { AssetService } from '../../../services/assets.service';
import { Component, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { ServiceRequest, ServiceRequestListResult } from 'app/models/ServiceRequest';

@Component({
  selector: 'serviceRequest-page',
  templateUrl: './serviceRequests-page.component.html',
  styleUrls: ['./serviceRequests-page.component.css']
})

export class ServiceRequestsPageComponent implements OnInit {
  
  @Input() state: string = "";

  public search: string = "";
  public page: number = 1;

  public filterForm: UntypedFormGroup;

  public showAddServiceRequestForm: boolean = false;

  constructor(
    private _assetService: AssetService,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if('search' in params){
        this.search = params.search;
      }
      if('page' in params){
        this.page = params.page;
      }
    })

    //this.loadServiceRequests(); 
  }

  /*loadServiceRequests() {
    this._assetService.getServiceRequests(this.tag,this.state,this.search, this.page).subscribe((data: ServiceRequestListResult) => {
      this.serviceRequests = data.serviceRequests;

      this.pageLinks = [];
      for(let i = 0; i< data.totalPages; i++){
        this.pageLinks.push(i);
      }

      this.updateRoute();
    })
  }

  updateOnNewServiceRequest(){
    this.search = "";

    this.loadServiceRequests();
  }*/

  updateRoute( ){
    var queryParams = {};
    if (this.search != "" ){queryParams['search'] = this.search;}
    if (this.page > 1){queryParams['page']=this.page;}
    this.router.navigate(['/service'], {queryParams: queryParams});
  }
  
  setShowAddServiceRequestForm(){this.showAddServiceRequestForm = true;}
  setHideAddServiceRequestForm(){this.showAddServiceRequestForm = false;}
}

