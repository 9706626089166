import { Component, OnInit, Input } from '@angular/core';
import { ServiceRequestLog } from 'app/models/ServiceRequestLog';

@Component({
  selector: 'serviceRequestLog-item',
  templateUrl: './serviceRequestLog-item.component.html',
  styleUrls: ['./serviceRequestLog-item.component.css']
})
export class ServiceRequestLogItemComponent implements OnInit {

  @Input() serviceRequest: ServiceRequestLog;

  constructor( ) { }

  ngOnInit() {

  }
}
