import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm, UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceRequestsListComponent } from 'app/elements/serviceRequest-list/serviceRequest-list.component';
import { Asset } from 'app/models/Asset';
import { NewServiceRequest } from 'app/models/ServiceRequest';
import { Unit } from 'app/models/Unit';
import { NewSigninFormComponent } from 'app/new-signin-form/new-signin-form.component';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs/internal/Subject';
import { debounceTime, distinctUntilChanged, filter, switchMap } from 'rxjs/operators';
import { AssetService } from 'services/assets.service';
import { UserService } from 'services/user.service';

@Component({
  selector: 'quickService-page',
  templateUrl: './quick-service.component.html',
  styleUrls: ['./quick-service.component.css']
})
export class QuickServicePageComponent implements OnInit {
//<todo> don't need to know the code at this point. Can skip the api call to get the code and get it on the back end.


  public loginDisplay = false;
  public showCreateForm = true;
  public showCreatedConfirmation = false;
  public newServiceNumber: string = '';
  public username: string = 'Guest';
  public signinSite = '';
  public serviceTarget = '';

  public open:string = 'open';

  @ViewChild(ServiceRequestsListComponent) ServiceRequestList: ServiceRequestsListComponent;

  constructor(
    private _assetService: AssetService,
    private _userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  //<todo> don't need to know the code at this point. Can skip the api call to get the code and get it on the back end.
  ngOnInit(): void {

    //Get reference to child object serviceRequetList


    this.serviceTarget = this.route.snapshot.paramMap.get('tag');

    this.loginDisplay = this._userService.isLoggedIn();
  }

  refreshServiceRequestList() {
    this.ServiceRequestList.loadServiceRequests();
  }
}
