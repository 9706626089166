<section class="filter-wrapper">
  <div class="keyword-wrapper">
    <input [formControl]="queryField" type="text" id="keyword" placeholder="Search for products..." autofocus/>
  </div>
  
  <ul *ngIf="products.length > 0" class="filter-select">
    <li *ngFor="let p of products" class="filter-select-list" (click)=onClickSelectProduct(p)>
      <img src="assets/media/DefaultProductIcon.png" alt="" width="60" height="60">
      <p class="product-item">
        {{p.manufacturer}}<br/>
        {{p.name}}<br/>
        <small>{{p.sku}}</small></p>
    </li>
  </ul>
 </section>