import { AssetService } from '../../../services/assets.service';
import { Component, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { ServiceRequest, ServiceRequestListResult } from 'app/models/ServiceRequest';

enum ServiceRequestPageState{
  Loading,
  Displaying,
  Error
}

@Component({
  selector: 'serviceRequestDetails-page',
  templateUrl: './serviceRequestsDetails-page.component.html',
  styleUrls: ['./serviceRequestsDetails-page.component.css']
})

export class ServiceRequestsDetailsPageComponent implements OnInit {

  public serviceRequest: ServiceRequest;
  public pageState: ServiceRequestPageState = ServiceRequestPageState.Loading;

  public pop_position: string = 'end';

  closeButtonText: string = "Close";

  constructor(
    private _assetService: AssetService,
    private router: Router,
    private route: ActivatedRoute) { }

  ngOnInit() {
    var srNumber = this.route.snapshot.paramMap.get('id');
    this._assetService.getServiceRequest(srNumber).subscribe((serviceRequest: ServiceRequest) => {
      this.serviceRequest = serviceRequest;
      this.pageState = ServiceRequestPageState.Displaying;
    });
  }

  closeServiceRequest(){
    this.closeButtonText = "Closing";
    this._assetService.setServiceRequestState(this.serviceRequest.number, "closed").subscribe(
      responce => {
          window.location.reload();
      },
      error => {
        this.closeButtonText = "Error";
      }
    );
  }

  refreshLogList(){
    this.pageState = ServiceRequestPageState.Loading;
    this._assetService.getServiceRequest(this.serviceRequest.number).subscribe((serviceRequest: ServiceRequest) => {
      this.serviceRequest = serviceRequest;
      this.pageState = ServiceRequestPageState.Displaying;
    });
  }

  get isLoading() { return this.pageState == ServiceRequestPageState.Loading; }
  get isDisplaying() { return this.pageState == ServiceRequestPageState.Displaying; }
  get isError() { return this.pageState == ServiceRequestPageState.Error; }

}

