import { AssetService } from '../../services/assets.service';
import { Component, OnInit, Output, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ProductListResult } from '../models/ProductListResult'
import { Product } from '../models/Product'

import { debounceTime,distinctUntilChanged,switchMap,filter } from 'rxjs/operators'
import { of } from 'rxjs';
//import { isNull } from 'util';

@Component({
  selector: 'product-search',
  templateUrl: './product-search.component.html',
  styleUrls: ['./product-search.component.css']
})

// https://medium.com/@nacimidjakirene/angular-search-autosuggest-with-observables-6f42987f80e6
export class ProductSearchComponent implements OnInit {

  public products: Array<Product> = [];
  public queryField: UntypedFormControl = new UntypedFormControl();

  public selectedProduct: Product = new Product();
  
  public sort: string = "";
  @Input() manufacturer = "";
  
  constructor(
    private _assetService: AssetService
  ) { }

  ngOnInit() {
    this._assetService.getProducts(this.manufacturer,"","",1)
    .subscribe((result: ProductListResult) =>
    {
      this.products = result.products;
    });

    const queryPipe = this.queryField.valueChanges
    .pipe(debounceTime(500))
    .pipe(distinctUntilChanged())

    const apiCallPipe = queryPipe
    .pipe(filter(query => query!=""))
    .pipe(switchMap( query => this._assetService.getProducts(this.manufacturer,query,"",1) ) )
    .subscribe((result: ProductListResult) =>
      {
        this.products = result.products;
      });
    
    const clearPipe = queryPipe
    .pipe(filter(query => query==""))
    .pipe(switchMap( query => this._assetService.getProducts(this.manufacturer,query,"",1) ) )
    .subscribe((result: ProductListResult) =>
    {
      this.products = result.products;
    });
  }

  public updateManufacturer(manufacturer){
    this.manufacturer = manufacturer;
    var search = this.queryField.value == null ? "" : this.queryField.value
    this._assetService.getProducts(this.manufacturer,search,"",1)
    .subscribe((result: ProductListResult) =>
    {
      this.products = result.products;
    });
  }

  onClickSelectProduct(selected: Product){
    this.selectedProduct = selected;
  }
}

