<div class="form-spacing">
  <h2>Set Property for {{data.asset}}</h2>

  <div mat-dialog-content>
    <div class="property-form">
      <div *ngIf="canEditPropertyName">
        <mat-form-field *ngIf="editProperty" class="property-form-element">
          <mat-label>Property</mat-label>
          <input matInput placeholder="Property" [(ngModel)]="data.property">
        </mat-form-field>
        <mat-form-field *ngIf="!editProperty" class="property-form-element">
          <mat-select placeholder="Select Property" [(ngModel)]="data.property">
            <mat-option *ngFor="let o of options" [value]="o.value">
              {{ o.viewValue }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-slide-toggle
            *ngIf="canAddNewProperty"
            class = "property-form-element"
            [(ngModel)]="editProperty">
              New Property {{editProperty ? 'On' : 'Off'}}
        </mat-slide-toggle>
      </div>
      <p *ngIf="!canEditPropertyName">{{data.property}}</p>

      <mat-form-field class="property-form-element">
        <textarea matInput
                  placeholder="Value"
                  [(ngModel)]="data.value"></textarea>
      </mat-form-field>

      <p>Asset: {{data.asset}}</p>
      <p>Property: {{data.property}}</p>
      <p>Value: {{data.value}}</p>

      <div mat-dialog-actions>
        <button mat-button type="button" (click)="onNoClick()">Cancel</button>
        <button mat-button type="button" [disabled]="!data.property || !data.value" [mat-dialog-close]="data">Ok</button>
      </div>
    </div>
  </div>
</div>