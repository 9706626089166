export class Product{
    sku: string;
    manufacturer: string;
    description: string;
    is_stock: boolean;
    is_asset: boolean;
    assetClass: string;
    units: string;
    scale: number;
    image: string;
}