import { AssetService } from '../../../services/assets.service';
import { Component, Inject, OnInit,ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder,Validators } from '@angular/forms';
import { ContractorSignin, ContractorSigninListResult } from 'app/models/ContractorSignin';
import { MsalBroadcastService, MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { InteractionStatus } from '@azure/msal-browser';
import { NewSigninFormComponent } from 'app/new-signin-form/new-signin-form.component';
import { UserService } from 'services/user.service';

@Component({
  selector: 'signin-list',
  templateUrl: './signin-list.component.html',
  styleUrls: ['./signin-list.component.css']
})

export class SigninListComponent implements OnInit {

  public signins: Array<ContractorSignin> = [];
  public pageLinks: Array<number> = []
  
  public site: string = "";
  public sort: string = "";
  public page: number = 1;

  public filterForm: UntypedFormGroup;

  public loginDisplay = false;
  private readonly _destroying$ = new Subject<void>();

  constructor(
    private _assetService: AssetService,
    private _userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
  ) { }

  ngOnInit() {

    this.loginDisplay = this._userService.isLoggedIn()

    this.route.queryParams.subscribe(params => {
      if('site' in params){
        this.site = params.site;
      }
    });

    this.filterForm = this.formBuilder.group({
      site: this.site
    })

    if(this.loginDisplay) {this.loadSignins(); }
  }

  loadSignins() {
    this._assetService.getSignins(this.site, this.sort, this.page).subscribe((data: ContractorSigninListResult) => {
      this.signins = data.signins;
      this.site = data.site;

      this.pageLinks = [];
      for(let i = 0; i< data.totalPages; i++){
        this.pageLinks.push(i);
      }

      this.updateRoute();
    })
  }

  updateRoute( ){
    var queryParams = {};
    if (this.site != "" ){queryParams['site'] = this.site;}
    if (this.sort != "" ){queryParams['sort'] = this.sort;}
    if (this.page > 1){queryParams['page']=this.page;}
    this.router.navigate(['/signin'], {queryParams: queryParams});
  }  

  updateOnNewSignin(){
    this.loadSignins();
  }

  onSiteChange(newSite){
    this.site = newSite;

    this.loadSignins()
  }

  navigatePage(page:number){
    this.page = page;
    this.loadSignins();
  }
}

