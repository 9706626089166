import { Component} from '@angular/core';
import { ProfileType, UserService } from 'services/user.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'AssetsFrontend';
  public loginDisplay = false;

  private isEngineer = false;
  private isMaintenance = false;
  
  public userProfile: ProfileType;
  public username: string = "";

  constructor(
    private _userService: UserService,
    ) { }

  ngOnInit() {

    this.loginDisplay = this._userService.isLoggedIn();
    if(this.loginDisplay){
      this.username = this._userService.getUsername();
      this.isEngineer = this._userService.hasRole("Engineer.Write");
    }
  }

  login() {
    this._userService.login();
  }

  logout() { this._userService.logout(); }

}
