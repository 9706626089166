<div class="form-spacing">
  <h2>Assign Asset to {{data.unit}}</h2>

  <div mat-dialog-content>
    <mat-form-field>
      <mat-label>Search Asset Tag</mat-label>
      <input matInput [formControl]="queryField" [(ngModel)]="data.asset" id="asset" placeholder="Enter asset name">
    </mat-form-field>
    <ul class="filter-select">
      <li *ngFor="let asset of assets" (click)=onClickSelectAsset(asset) class="filter-select-list">
        <div>{{asset.tag}}</div>
      </li>
    </ul>
  </div>

  <div *ngIf="data.asset">
    Selected asset {{data.asset}}
  </div>

  <div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">Cancel</button>
    <button mat-button [disabled]="!data.asset" [mat-dialog-close]="data.asset">Ok</button>
  </div>
</div>
