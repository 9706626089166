import { ServiceRequest } from "./ServiceRequest";

export class ServiceRequestLog{
    serviceRequest: string;
    timestamp: Date;
    company: string;
    name: string;
    action: string;
    description: string;
    target: string;
}

export class ServiceRequestValid{
    valid: boolean;
}
