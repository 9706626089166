<div class="form-spacing">
  <h2>Assign Asset {{data.asset}}</h2>

  <div mat-dialog-content>
    <mat-form-field>
      <mat-label>Search Asset Tag</mat-label>
      <!--input matInput [formControl]="queryField" [(ngModel)]="data.unit" id="unit" placeholder="Enter asset name"-->
      <input matInput [formControl]="queryField" id="unit" placeholder="Enter asset name">
    </mat-form-field>
    <ul class="filter-select">
      <li *ngFor="let unit of units" (click)=onClickSelectUnit(unit) class="filter-select-list">
        <div>{{unit.unit}}</div>
      </li>
    </ul>
  </div>

  <div *ngIf="data.unit">
    Selected unit {{data.unit}}
  </div>

  <div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">Cancel</button>
    <button mat-button [disabled]="!data.unit" [mat-dialog-close]="data.unit">Ok</button>
  </div>
</div>
