import {Component, Inject, OnInit} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {FormsModule, UntypedFormControl} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';

import { AssetService } from '../../../services/assets.service';
//import { Asset } from 'app/models/Asset';

import { debounceTime,distinctUntilChanged,switchMap,filter, tap } from 'rxjs/operators'
import { Observable } from 'rxjs';
import { Product } from 'app/models/Product';
import { UserService } from 'services/user.service';


@Component({
  selector: 'assetPropertyNewPopup',
  templateUrl: './assetPropertyNewPopup.component.html',
  styleUrls: ['./assetPropertyNewPopup.component.css']
})
export class assetPropertyNewPopupComponent implements OnInit {

  options = [
    { value: 'Gearbox Ratio', viewValue: 'Gearbox Ratio' },
    { value: 'Length', viewValue: 'Length' },
  ]

  public canEditPropertyName = true;
  public canAddNewProperty = this._userService.hasRole('Engineer.Write');

  public editProperty: boolean = false;

  constructor(
    private _assetService: AssetService,
    private _userService: UserService,
    public dialogRef: MatDialogRef<assetPropertyNewPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PropertyNewDialogData
  ) { }

  ngOnInit() {
    if(this.data.property != ''){
      this.canEditPropertyName = false;
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}

export interface PropertyNewDialogData {
  asset: string;
  property: string;
  value: string;
}