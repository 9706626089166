export class ContractorSignin{
    id: string;
    contractorName: string;
    company: string;
    phone: string;
    time: Date;
    site: string;
    serviceNumber: string;
}
export class newContractorSignin{
    contractorName: string;
    company: string;
    phone: string;
    serviceNumber: string;
    site: string;
}

export class ContractorSigninListResult{
    signins: Array<ContractorSignin>;
    totalPages: number;
    page: number;
    site: string;
    search: string;
    message: string;
}