import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams} from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { throwError, Observable } from 'rxjs';

import { environment } from '../environments/environment'

import { NewProduct } from '../app/models/NewProduct';
import { NewPurchase } from '../app/models/NewPurchase';
import { Asset } from '../app/models/Asset';
import { Product } from '../app/models/Product';
import { ContractorSignin, newContractorSignin } from 'app/models/ContractorSignin';
import { NewServiceRequest, ServiceRequest } from 'app/models/ServiceRequest';
import { QuickUnit, Unit, UnitAsset, UnitStock, UnitStockLog, UnitStockUpdateDTO } from 'app/models/Unit';
import { ServiceRequestLog, ServiceRequestValid } from 'app/models/ServiceRequestLog';
import { StockListResult, newStocktake } from 'app/models/StockListResult';
import { error } from 'console';


@Injectable({providedIn: 'root'})
export class AssetUnitService {

  API_URL = environment.assetsAPIUrl;

  constructor(private _http: HttpClient) { }


  getUnits(search: string, sort: string, page: number){
    let params = new HttpParams();

    if(search != ""){
      params = params.append("search", search);
    }
    if(sort != ""){
      params = params.append("sort", sort);
    }
    if(page > 1){
      params =params.append("page",String(page))
    }

    return this._http.get(this.API_URL + "/api/units", {params})
  }
  getUnit(unit: string){
    return this._http.get<Unit>(this.API_URL + "/api/units/" + unit);
  }
  
  addUnit(unit: Unit){
    return this._http.post(this.API_URL + "/api/units", unit);
  }

  getUnitAssets(unit: string): Observable<UnitAsset[]>{
    return this._http.get<UnitAsset[]>(this.API_URL + "/api/units/" + unit + "/assets");
  }

  assignAsset(unit: string, asset: string){
    return this._http.post(this.API_URL + "/api/units/" + unit + "/assets", {action: "assign", asset: asset});
  }

  removeAsset(unit: string, assignmentid: string){
    return this._http.put(this.API_URL + "/api/units/" + unit + "/assets", {action: "unassign", assignmentid: assignmentid});
  }

  getUnitStock(unit: string): Observable<UnitStock[]>{
    return this._http.get<UnitStock[]>(this.API_URL + "/api/units/" + unit + "/stock");
  }

  addUnitStock(unit: string, stock: string){
    return this._http.post(this.API_URL + "/api/units/" + unit + "/stock", {sku: stock});
  }

  updateUnitStock(unit: string, stock: string, quantity: number){
    return this._http.put(this.API_URL + "/api/units/" + unit + "/stock", {sku: stock, quantity: quantity});
  }

  deleteUnitStock(unit: string, stock: string){
    return this._http.delete(this.API_URL + "/api/units/" + unit + "/stock", {body: {sku: stock,quanity: 0}});
  }

  getUnitStockLog(unit: string, stock: string): Observable<UnitStockLog[]>{
  
    return this._http.get<UnitStockLog[]>(this.API_URL + "/api/units/" + unit + "/stock/log", {params: {sku: stock}});
  }
}