import {Component, Inject, OnInit} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {FormsModule, UntypedFormControl} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';

import { AssetService } from '../../../services/assets.service';
import { Asset } from 'app/models/Asset';

import { debounceTime,distinctUntilChanged,switchMap,filter, tap } from 'rxjs/operators'
import { Observable } from 'rxjs';
import { AssetUnitService } from 'services/assets.units.service';
import { Unit } from 'app/models/Unit';

@Component({
  selector: 'assignAssetToUnitPopup',
  templateUrl: './assignAssetToUnitPopup.component.html',
  styleUrls: ['./assignAssetToUnitPopup.component.css']
})
export class assignAssetToUnitPopupComponent implements OnInit {

  public units: Array<Unit> = [];
  public queryField: UntypedFormControl = new UntypedFormControl();

  public selectedUnit: Unit = null;

  constructor(
    private _assetService: AssetService,
    private _assetUnitService: AssetUnitService,
    public dialogRef: MatDialogRef<assignAssetToUnitPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  ngOnInit() {
    const queryPipe = this.queryField.valueChanges
    .pipe(debounceTime(500))
    .pipe(distinctUntilChanged())

    const apiCallPipe = queryPipe
    .pipe(filter(query => query.length >= 3))
    .pipe(switchMap( query => this._assetUnitService.getUnits(query,"",1) ) )
    .subscribe((result: any) =>
      {
        this.units = result.units;
      });
    
    // If length is less than 4, clear assets list
    const clearPipe = queryPipe
    .pipe(filter(query => query.length < 3))
    .subscribe( query => this.units = []);
  }

  onClickSelectUnit(unit: Unit){
    this.data.unit = unit.unit;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}

export interface DialogData {
  unit: string;
  asset: string;
}
