import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-stock-list-all',
  templateUrl: './stock-list-all.component.html',
  styleUrls: ['./stock-list-all.component.css']
})
export class StockListAllComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
