<div *ngIf="isLoading">
  <H2>Loading...</H2>
</div>

<div *ngIf="isDisplaying">
  <H2>{{asset.tag}}</H2>
  <p>Created By: {{asset.createdBy}} on {{asset.createdDate+"Z" | date:'MMM d, y, H:mm'}} </p>
  <div  class="details-grid">
    <div class="details-grid-details">
      Serial: {{asset.serial}} <br/>
      Product: {{asset.productId}} <br/>
      State: {{asset.state}} 
    </div>
    <div class="details-grid-qr">
      <qr-code
          value="https://portal.dfxpress.online/quickservice/{{asset.tag}}"
          size="150"
          margin="6"
          routerLink="/quickservice/{{asset.tag}}"
          routerLinkActive='active'>
      </qr-code><br/>
    </div>
  </div>

  <div>
    <h2>Assignement</h2>
    <div *ngIf="asset.state == 'available'">
      <button (click)="openAssignAssetPopup()">Assign</button>
    </div>
    <div>
      <asset-assignments [assetTag]='asset.tag'></asset-assignments>
    </div>
  </div>

  <div>
    <div class="flex-row">
      <div class="left-fill-spacer"><h1>Asset Properties</h1></div>
      <button class="button-padding" (click)="openAssetPropertyNewPopup()" mat-flat-button>+</button>
    </div>

    <ul>
      <li *ngFor="let p of properies" class="property-list-grid">
        <div class="property-list-grid-label">{{p.property}}</div>
        <div class="property-list-grid-value dataElement" (click)='setProperty(p.property)'>{{p.value}}</div>
        <div class="property-list-grid-delete" *ngIf="canDeleteProperty">
          <button (click)="deleteProperty(p.property)" mat-flat-button>X</button>
        </div>
      </li>
    </ul>
        
  </div>
</div>

<div *ngIf="isError">
  <H2>Error Loading Asset</H2>
</div>