<div style="width: 600px">
  <H2>{{purchase.name}}</H2>

    <small>Created at: {{purchase.timestamp}} by {{purchase.creator}}</small>
    <table>
      <tr>
        <th style="width:50;">SKU</th>
        <th style="width:50;">Product</th>
        <th style="width:50;">Manufacturer</th>
        <th style="width:20;">Quantity</th>
      </tr>

      <tbody *ngFor="let item of purchase.purchaseItems">
        <tr >
            <td style="width: 50; margin: 5px 2px"><small>{{item.product.sku}}</small></td>
            <td style="width: 50; margin: 5px 2px">{{item.product.name}}</td>
            <td style="width: 50; margin: 5px 2px">{{item.product.manufacturer}}</td>
            <td style="width: 50; margin: 5px 2px">{{item.quantity}}</td>
        </tr>
        <tr *ngFor="let p of item.properties">
          <td></td>
          <td style="padding-left: 20px" >{{p.description}}: {{p.value}}</td> 
        </tr>
      </tbody>
    </table>
  <hr/>
  <form [formGroup]="addProductForm" (ngSubmit)="AddProduct()">
    New Product: {{search.selectedProduct.sku}}<br/>
    Quantity <input type="text" formControlName="newQuantity">
    <button> Add </button>
    <p>
      <small *ngIf="addProductForm.get('newQuantity').hasError('required')"> Quantity required </small>
      <small *ngIf="addProductForm.get('newQuantity').hasError('min')"> Quantity must be a number greater than 1 </small>
    </p>
  </form>
  <hr/>
  <manufacturer-select #manufacturerSelect (OnManufacturerChangeCallback)="search.updateManufacturer($event[0])"></manufacturer-select>
  <product-search #search [manufacturer]="manufacturerSelect.manufacturer"></product-search>
</div>