import { AssetService } from '../../../services/assets.service';
import { Component, ElementRef, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { ServiceRequest, ServiceRequestListResult } from 'app/models/ServiceRequest';
import { MatSidenav } from '@angular/material/sidenav';

enum ServiceRequestListState{
  Loading,
  Displaying,
  Error
}

@Component({
  selector: 'serviceRequest-list',
  templateUrl: './serviceRequest-list.component.html',
  styleUrls: ['./serviceRequest-list.component.css']
})

export class ServiceRequestsListComponent implements OnInit {

  private pageState: ServiceRequestListState = ServiceRequestListState.Loading;

  public serviceRequests: Array<ServiceRequest> = [];
  public pageLinks: Array<number> = []
  
  @Input() tag: string = "";
  @Input() state: string = "";

  @ViewChild(ServiceRequestsListComponent) ServiceRequestList: ServiceRequestsListComponent;

  @ViewChild('listContainer') listContainer: ElementRef;
  @ViewChild('newPopup') newPopup: MatSidenav;
  @ViewChild('newPopup') newPopupElement: ElementRef;
  public pop_position: string = 'end';

  //private currentTag: string = this.tag;

  private search: string = "";
  private page: number = 1;

  constructor(
    private _assetService: AssetService,
    private formBuilder: UntypedFormBuilder) { }

  ngOnInit() {
    this.loadServiceRequests();
  }

  loadServiceRequests() {
    this._assetService.getServiceRequests(this.tag ,this.state,this.search, this.page)
      .subscribe((data: ServiceRequestListResult) => {
        this.serviceRequests = data.serviceRequests;
        this.pageState = ServiceRequestListState.Displaying;

        this.pageLinks = [];
        for(let i = 0; i< data.totalPages; i++){
          this.pageLinks.push(i);
        }

        //this.currentTag = this.tag;
      },
      error => {
        this.pageState = ServiceRequestListState.Error;
      }
    )
  }

  refreshServiceRequestList() {
    this.ServiceRequestList.loadServiceRequests();
  }

  navigatePage(page:number){
    this.page = page;
    this.loadServiceRequests();
  }

  get isLoading(){ return this.pageState == ServiceRequestListState.Loading; }

  get isDisplaying(){ return this.pageState == ServiceRequestListState.Displaying; }

  get isError(){ return this.pageState == ServiceRequestListState.Error; }
  
}

