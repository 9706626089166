import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams} from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { throwError, Observable } from 'rxjs';

import { environment } from '../environments/environment'

import { NewProduct } from '../app/models/NewProduct';
import { NewPurchase } from '../app/models/NewPurchase';
import { Asset, AssetProperty } from '../app/models/Asset';
import { Product } from '../app/models/Product';
import { ContractorSignin, newContractorSignin } from 'app/models/ContractorSignin';
import { NewServiceRequest, ServiceRequest } from 'app/models/ServiceRequest';
import { QuickUnit, Unit, UnitAsset } from 'app/models/Unit';
import { ServiceRequestLog, ServiceRequestValid } from 'app/models/ServiceRequestLog';
import { StockListResult, newStocktake } from 'app/models/StockListResult';
import { error } from 'console';


@Injectable({providedIn: 'root'})
export class AssetService {
  getAssetAssignments(tag: string) {
    return this._http.get<UnitAsset[]>(this.API_URL + "/api/assets/" + tag + "/assignments")
  }

  API_URL = environment.assetsAPIUrl;

  getProduct(sku: string): Observable<Product>{
    return this._http.get<Product>(this.API_URL + "/api/products/" + sku)
  }

  constructor(private _http: HttpClient) { }

  getSignins(site: string, sort: string, page: number){
    let params = new HttpParams();
    
    if(site != ""){
      params = params.append("site", site);
    }
    if(sort != ""){
      params = params.append("sort", sort);
    }
    if(page > 1){
      params =params.append("page",String(page))
    }

    return this._http.get(this.API_URL + "/api/signin", {params})
  }

  addSignin(newSignin: newContractorSignin): Observable<ContractorSignin>{
    return this._http.post(this.API_URL + "/api/signin", newSignin).pipe(
      map((signin: any) => {
        var s:ContractorSignin = new ContractorSignin();
        s.contractorName = signin.contractorName;
        s.company = signin.company;
        s.site = signin.site;
        s.time = signin.time;
        s.serviceNumber = signin.serviceNumber;
        s.phone = signin.phone;
        return s
      })
    )
  }

  getAssets(search: string, sort: string, page: number, state: string){
    let params = new HttpParams();

    if(search != ""){
      params = params.append("search", search);
    }
    if(sort != ""){
      params = params.append("sort", sort);
    }
    if(page > 1){
      params =params.append("page",String(page))
    }
    if(state != ""){
      params =params.append("state",state)
    }

    return this._http.get(this.API_URL + "/api/assets", {params}).pipe(
      catchError(error => {
        return throwError(error);
      })
    );
  }

  getAsset(tag: string): Observable<Asset>{
    return this._http.get<Asset>(this.API_URL + "/api/assets/"+tag);//.pipe(

  }

  getAsseteProperties(tag: string){
    return this._http.get<AssetProperty[]>(this.API_URL + "/api/assets/" + tag + "/properties");
  }

  setAssetProperty(asset: string, property: string, value: string){
    return this._http.post(this.API_URL + "/api/assets/" + asset + "/properties", {"property": property, "value": value})
  }

  deleteAssetProperty(asset: string, property: string){
    return this._http.delete(this.API_URL + "/api/assets/" + asset + "/properties", {"body": {"property": property, "value": "0"}})
  }

  getAssetServiceRequests(tag: string){
    return this._http.get<ServiceRequestLog[]>(this.API_URL + "/api/assets/" + tag + "/servicelog");
  }

  getAssetOpenServiceRequests(tag: string){
    return this._http.get<ServiceRequestLog[]>(this.API_URL + "/api/assets/" + tag + "/openservicerequests");
  }

  validateServiceRequests(srNumber: string){
    return this._http.get<ServiceRequestValid>(this.API_URL + "/api/servicerequest/validate/" + srNumber );
  }
  
  addAsset(sku: string, serial: string){
    var postBody={
      'Serial': serial,
      'ProductID': sku
    }

    return this._http.post<Asset>(this.API_URL + "/api/assets", postBody)
  }

  getStock(search: string, sort: string, page: number): Observable<StockListResult> {
    let params = new HttpParams();

    if(search != ""){
      params = params.append("search", search);
    }
    if(page > 1){
      params =params.append("page",String(page))
    }
    
    return this._http.get<StockListResult>(this.API_URL + "/api/stock/",{params}).pipe(
      catchError(error => {
        return throwError(error);
      })
    );
  }

  updateStocktake(stocktake: newStocktake){
    return this._http.post(this.API_URL + "/api/stock/" + stocktake.location + "/" + stocktake.sku, stocktake).pipe(
      catchError(error => {
        return throwError(error);
      })
    );
  }

  getSkuQuantityAtLocation(location: string, sku: string){
    return this._http.get(this.API_URL + "/api/stock/" + location + "/" + sku)
  }


  getProducts(manufacturer: string, search: string, sort: string, page: number){
    let params = new HttpParams();

    if(manufacturer != ""){
      params = params.append("manufacturer", manufacturer);
    }
    if(search != ""){
      params = params.append("search", search);
    }
    if(sort != ""){
      params = params.append("sort", sort);
    }
    if(page > 1){
      params =params.append("page",String(page))
    }

    return this._http.get(this.API_URL + "/api/products", {params})
  }

  getProductsStock(manufacturer: string, search: string, sort: string, page: number){
    let params = new HttpParams();

    if(manufacturer != ""){
      params = params.append("manufacturer", manufacturer);
    }
    if(search != ""){
      params = params.append("search", search);
    }
    if(sort != ""){
      params = params.append("sort", sort);
    }
    if(page > 1){
      params =params.append("page",String(page))
    }

    return this._http.get(this.API_URL + "/api/products/stock", {params})
  }

  getManufacturers(){
    return this._http.get(this.API_URL + "/api/manufacturers")
  }

  addProduct(product: NewProduct){
    return this._http.post(this.API_URL + "/api/products",product)
                     .pipe(catchError(this.errorHandler))
  }

  getServiceRequests(tag:string, state: string, search: string, page: number){
    let params = new HttpParams();

    if(tag != ""){
      params = params.append("tag", tag);
    }
    
    if(state != ""){
      params = params.append("state", state);
    }

    if(search != ""){
      params = params.append("search", search);
    }
    
    if(page > 1){
      params =params.append("page",String(page))
    }

    return this._http.get(this.API_URL + "/api/servicerequest", {params})
  }

  getServiceRequest(tag:string): Observable<ServiceRequest> {
    return this._http.get<ServiceRequest>(this.API_URL + "/api/servicerequest/" + tag)
  }

  getServiceRequestLog(tag:string): Observable<ServiceRequestLog[]> {
    return this._http.get<ServiceRequestLog[]>(this.API_URL + "/api/servicerequest/" + tag + "/log" )
  }


  setServiceRequestState(tag: string, state: string){
    return this._http.post(this.API_URL + "/api/servicerequest/" + tag + "/state", "", {params:{"state": state}, observe: 'response'})
  }

  addServiceRequest(serviceRequest: NewServiceRequest){
    return this._http.post(this.API_URL + "/api/servicerequest",serviceRequest)
                     .pipe(catchError(this.errorHandler))
  }

  addServiceRequestLogEvent(serviceRequestLogEvent: ServiceRequestLog){
    return this._http.post(this.API_URL + "/api/servicerequest/logevent",serviceRequestLogEvent)
                     .pipe(catchError(this.errorHandler))
  }

  getPurchases(search: string, sort: string, page: number){
    let params = new HttpParams();

    if(search != ""){
      params = params.append("search", search);
    }
    if(sort != ""){
      params = params.append("sort", sort);
    }
    if(page > 1){
      params =params.append("page",String(page))
    }

    return this._http.get(this.API_URL + "/api/purchases", {params})
  }

  addPurchase(purchase: NewPurchase){
    return this._http.post(this.API_URL + "/api/purchases",purchase)
                     .pipe(catchError(this.errorHandler))
  }

  getPurchase(id: string){
    return this._http.get(this.API_URL + "/api/purchases/"+id)
                     .pipe(catchError(this.errorHandler));
  }

  addProductToPurchase(id: string, product: string, quantity: number){
    return this._http.post(this.API_URL + "/api/purchases/"+id,{"action": "AddProduct" , "product": product,"quantity":quantity})
  }
  
  addProductPropertyToPurchase(id: string, itemId: string, property: string, value: string){
    return this._http.post(this.API_URL + "/api/purchases/"+id,{"action": "AddProperty" , "itemId": itemId , "property": property,"value":value})
  }

  errorHandler(error: HttpErrorResponse){
    return throwError(error)
  }
}