export class ServiceRequest{
    number: string;
    title: string;
    state: string;
    target: string;
    site: string;
    description: string;
    createdOn: Date;
    createdBy: string;
}

export class ServiceRequestListResult{
    serviceRequests: Array<ServiceRequest>;
    totalPages: number;
    page: number;
    search: string;
    message: string;
}

export class NewServiceRequest{
    constructor(
        public title: string,
        public target: string,
        public code: number,
        public description: string,
        public createdBy: string
    ){}
}