
<div *ngIf="isLoading">
  <H2>Not Initialised</H2>
</div>

<div *ngIf="isLoading">
  <H2>Loading...</H2>
</div>

<div *ngIf="isDisplaying">
  <h3>Log</h3>
  <ul>
    <li *ngFor="let l of unitStockLog" >
      {{l.actionDate | date:'medium'}} {{l.actionBy}} {{l.action}} 
    </li>
  </ul>
</div>

<div *ngIf="isError">
  <H2>Failed to Load...</H2>
</div>
