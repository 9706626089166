export const environment = {
  production: true,
  assetsAPIUrl: "https://dfxassetsapi.azurewebsites.net",
  azureAuthRedurecUri: 'https://portal.dfxpress.online'
};

export const sites ={
  'Adelaide':	"ADL",
  'Brisbane':	"BNE",
  'Melbourne':	"MEL",
  'Canberra':	"CAN",
  'Sunshine Coast':	"SUN",
  'Dandenong':	"DAN",
  'Gold Coast':	"GLC",
  'Kemps Creek':	"KEC",
  'Newcastle':	"NEW",
  'Perth':	"PER",
  'Sydney':	"SYD",
};
