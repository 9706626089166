import { Inject, Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';

import { MsalBroadcastService, MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType, InteractionStatus, PopupRequest } from '@azure/msal-browser';
import { Subject, firstValueFrom } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me';

export type ProfileType = {
  givenName?: string,
  surname?: string,
  userPrincipalName?: string,
  id?: string
};

@Injectable({providedIn: 'root'})
export class UserService {

  profile!: ProfileType;
  private token: string = "";
  private tokenExpiresOn: Date = new Date();
  public loginDisplay = false;
  public roles: string[] = [];
  public groups: string[] = [];
  private readonly _destroying$ = new Subject<void>();

  constructor(
    private _http: HttpClient,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private broadcastService: MsalBroadcastService,
    private authService: MsalService
  ) { 

    this.broadcastService.msalSubject$
    .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
    )
    .subscribe((result: EventMessage) => {
        const payload = result.payload as AuthenticationResult;
        this.authService.instance.setActiveAccount(payload.account);
    });

    this.broadcastService.inProgress$
    .pipe(
      filter((status: InteractionStatus) => status === InteractionStatus.None),
      takeUntil(this._destroying$)
    )
    .subscribe(() => {
      this.setLoginStatus();
    })
    }


  getProfile() {
    return this._http.get('https://graph.microsoft.com/v1.0/me');
  }

  getTeams() {
    return this._http.get("https://graph.microsoft.com/v1.0/me/joinedTeams");
  }

  login() {
    if (this.msalGuardConfig.authRequest){
      this.authService.loginPopup({...this.msalGuardConfig.authRequest} as PopupRequest)
      .subscribe({
        next: (result) => {
          console.log(result);
          this.setLoginStatus();

          //Reload page to show login status
          window.location.reload();
        },
        error: (error) => console.log(error)
      });;
    } else {
    this.authService.loginPopup()
      .subscribe({
        next: (result) => {
          console.log(result);
          this.setLoginStatus();

          //Reload page to show login status
          window.location.reload();
        },
        error: (error) => console.log(error)
      });
    }

  }

  logout() { 
    this.authService.logoutPopup({
      mainWindowRedirectUri: "/"
    });
  }

  getUsername():string{
    if (this.authService.instance.getAllAccounts().length < 1) return "";

    this.authService.acquireTokenSilent
    return this.authService.instance.getAllAccounts().pop().username;
  }

  setLoginStatus() {

    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;

    if (!this.loginDisplay) {
      this.roles = [];
      this.groups = [];
      return;
    }

    //Get roles
    var token =  this.authService.instance.getActiveAccount()?.idTokenClaims;
    this.roles = token.roles;

    //Get Groups (Teams)
    //this._http.get("https://graph.microsoft.com/v1.0/me/transitiveMemberOf/microsoft.graph.group?$select=displayName").subscribe(groups => {
    this._http.get("https://graph.microsoft.com/v1.0/me/transitiveMemberOf/microsoft.graph.group?").subscribe(groups => {
      this.groups = groups["value"].map((group: any) => group["displayName"]);
    });

    this.refreshStorageToken()
  }

  getRoles():string[]{
    return this.roles;
  }

  get Groups():string[]{
    return this.groups;
  }

  hasGroup(group: string):boolean{
    return this.Groups.includes(group) || this.roles.includes("Engineer.Write");
  }

  hasRole(role: string):boolean{
    if (this.authService.instance.getAllAccounts().length < 1) return false;

    var token =  this.authService.instance.getActiveAccount()?.idTokenClaims;
    this.roles = token.roles;
    return this.roles.includes(role) || this.roles.includes("Engineer.Write");
  }

  hasLocation(location: string):boolean{

    return this.groups.includes(location) || this.roles.includes("Engineer.Write");
  
  }

  isLoggedIn(){
    return this.authService.instance.getAllAccounts().length > 0;
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  get storageToken(): string {
    //Get values from local storage
    let token = localStorage.getItem('storagetoken') || "";
    let tokenExpiresOn = new Date(localStorage.getItem('storagetokenexpiry') || "");

    //Javascript won't wait for the refresh token to be refreshed so just have to load here and try again next time
    if (tokenExpiresOn < new Date()) {
      this.refreshStorageToken()
    }

    return token;
  }

  async refreshStorageToken() {

    let token = localStorage.getItem('storagetoken') || "";
    let tokenExpiresOn = new Date(localStorage.getItem('storagetokenexpiry') || "");

    if (tokenExpiresOn > new Date()) {
      this.token = token;
      this.tokenExpiresOn = tokenExpiresOn;
      return;
    }

    const tokenRequest = {
      scopes: ['https://storage.azure.com/user_impersonation']
    }
    const request = this.authService.acquireTokenSilent(tokenRequest);
    const responce = await firstValueFrom(request);
    this.token = responce.accessToken;
    this.tokenExpiresOn = responce.expiresOn;

    localStorage.setItem('storagetoken', responce.accessToken);
    localStorage.setItem('storagetokenexpiry', responce.expiresOn.toISOString());
  }

}