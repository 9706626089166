
import { Component, Input, OnInit, } from '@angular/core';

import { AssetUnitService } from 'services/assets.units.service';
import { UnitStockLog } from 'app/models/Unit';

enum UnitStockHistoryState{
  Waiting,
  Loading,
  Displaying,
  Error
}

@Component({
  selector: 'unitstocklog',
  templateUrl: './unitStockLog.component.html',
  styleUrls: ['./unitStockLog.component.css']
})

export class UnitStockHistoryComponent implements OnInit {

  private pageState: UnitStockHistoryState = UnitStockHistoryState.Waiting;

  public unitStockLog: Array<UnitStockLog> = [];
  
  @Input() unit: string = "";
  @Input() sku: string = "";

  constructor(
    private _assetUnitService: AssetUnitService
  ) { }

  ngOnInit() {
    this.loadServiceRequests();
  }

  loadServiceRequests() {

    //Check that the unit and sku are not empty
    if(this.unit == "" || this.sku == ""){
      this.pageState = UnitStockHistoryState.Waiting;
      return;
    }

    this.pageState =UnitStockHistoryState.Loading;

    this._assetUnitService.getUnitStockLog(this.unit ,this.sku)
      .subscribe((log) => {
        this.unitStockLog = log;
        this.pageState = UnitStockHistoryState.Displaying;
      },
      error => {
        this.pageState = UnitStockHistoryState.Error;
      }
    )
  }


  get isLoading(){ return this.pageState == UnitStockHistoryState.Loading; }

  get isDisplaying(){ return this.pageState == UnitStockHistoryState.Displaying; }

  get isError(){ return this.pageState == UnitStockHistoryState.Error; }
  
}

