import { AssetService } from '../../../services/assets.service';
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { StockCountDTO, StockListResult, newStocktake } from '../../models/StockListResult';
import { Stocktake } from '../../models/Stocktake';
import { MatSidenav } from '@angular/material/sidenav';
import { UserService } from 'services/user.service';
import { debounceTime, distinctUntilChanged, filter, switchMap } from 'rxjs/operators';

enum StockPageState{
  Loading,
  Displaying,
  Error
}

@Component({
  selector: 'stock-list',
  templateUrl: './stock-list.component.html',
  styleUrls: ['./stock-list.component.css']
})

export class StockListComponent implements OnInit, AfterViewInit {

  private pageState: StockPageState = StockPageState.Loading;
  
  @ViewChild('listContainer') listContainer: ElementRef;
  @ViewChild('editPopup') editPopup: ElementRef;

  @ViewChild('sidePop') sidePop: MatSidenav;
  public pop_position: string = 'end';

  public stock: Array<StockCountDTO> = [];
  public stockList: Array<object> = [];
  public locationHeaders: Array<string> = [];
  public displayHeaders: Array<string> = [];
  public pageLinks: Array<number> = []
  
  public search: string = "";
  public sort: string = "";
  public page: number = 1;

  public queryField: UntypedFormControl = new UntypedFormControl();

  public filterForm: UntypedFormGroup;

  public showAddProductForm: boolean = false;
  public selectedSku: string;
  public selectedLocation: string;
  public selectedQuantity: number;
  public updateMessage: string = "";

  constructor(
    private _assetService: AssetService,
    private _user: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder) { }

  ngAfterViewInit(): void {
    this.adjustPopupWidth();
    window.addEventListener('resize', this.adjustPopupWidth.bind(this));
  }

  adjustPopupWidth(){
    const containerWidth= this.listContainer.nativeElement.offsetWidth;
    const popupWidth = Math.min(containerWidth, 500);
    this.editPopup.nativeElement.style.width = popupWidth + "px";
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {

      if('search' in params){
        this.search = params.search;
      }
      if('sort' in params){
        this.sort = params.sort;
      }
      if('page' in params){
        this.page = params.page;
      }
    })
    this.loadStock();

    const queryPipe = this.queryField.valueChanges
    .pipe(debounceTime(500))
    .pipe(distinctUntilChanged())

    const apiCallPipe = queryPipe
    .subscribe(query => {
      this.search = query;
      this.loadStock();
    });
    /*
    .pipe(filter(query => query!=""))
    .pipe(switchMap( query => this._assetService.getStock(query, this.sort, this.page)) )
    .subscribe(this.readStock);

    const clearPipe = queryPipe
    .pipe(filter(query => query==""))
    .pipe(switchMap( query => this._assetService.getStock(query, this.sort, this.page)) )
    .subscribe(this.readStock);
    */
  }

  loadStock(){
    this.pageState = StockPageState.Loading;
    this._assetService.getStock(this.search, this.sort, this.page).subscribe(this.readStock);
  }

  private readStock = {
    next: (data: StockListResult) => {
      this.stock = data.stock;
      this.pageState = StockPageState.Displaying;
      
      this.displayHeaders = this.stock.length > 0 ? ['Image','SKU',...Object.keys(data.stock[0].locations)] : [];
      this.locationHeaders = this.stock.length > 0 ? [...Object.keys(data.stock[0].locations)] : [];

      this.pageLinks = [];
      for(let i = 0; i< data.totalPages; i++){
        this.pageLinks.push(i);
      }
      this.updateRoute();
    },
    error: (e : any) => {
      this.pageState = StockPageState.Error;
    }
  }

  updateRoute( ){
    var queryParams = {};
    if (this.search != "" ){queryParams['search'] = this.search;}
    if (this.sort != "" ){queryParams['sort'] = this.sort;}
    if (this.page > 1){queryParams['page']=this.page;}
    this.router.navigate(['/stock'], {queryParams: queryParams});
  }

  navigatePage(page:number){
    this.page = page;
    this.loadStock();
  }
 
  setShowAddProductForm(value: boolean){this.showAddProductForm = true;}
  setHideAddProductForm(value: boolean){this.showAddProductForm = false;}

  get isLoading(){ return this.pageState == StockPageState.Loading;}

  get isDisplaying(){ return this.pageState == StockPageState.Displaying;}

  get isError(){ return this.pageState == StockPageState.Error;}

  setSelection(sku, location, quantity){
    this.selectedSku = sku;
    this.selectedLocation = location;
    this.selectedQuantity = quantity;

    this.sidePop.open();
  }

  newQuantity(sku, location, quantity){

    var newStock = new newStocktake();
    newStock.sku = this.selectedSku;
    newStock.location = this.selectedLocation;
    newStock.quantity = this.selectedQuantity;
    newStock.action = "Set";
    this._assetService.updateStocktake(newStock).subscribe(this.updateStock);
  } 

  get canUpdateStock(){
    return this._user.hasLocation(this.selectedLocation)
  }


  private updateStock = {
    next: (result: any) => {
      this.updateMessage = "Updated";
      this._assetService.getStock(this.search, this.sort, this.page).subscribe(this.readStock);
    },
    error: (e : any) => {
      this.pageState = StockPageState.Error;
    }
  }
}
