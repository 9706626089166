import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ProfileType, UserService } from 'services/user.service';

const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me';


@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  profile!: ProfileType;
  everything: string;
  teams: string;
  roles: string[] = [];
  groups: string[] = [];

  constructor(private http: HttpClient, public _userService: UserService) { }

  ngOnInit(): void {
    this.getProfile();
    this.roles = this._userService.getRoles();
    this.groups = this._userService.groups;
  }

  getProfile() {
    this._userService.getProfile()
      .subscribe(profile => {
        this.profile = profile;
        this.everything = JSON.stringify(profile)
      });
  }
}
