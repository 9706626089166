<div style="width: 600px">
  <H2>Purchases</H2>
  <div *ngIf="!showAddPurchaseForm"><button (click)="setShowAddPurchaseForm()">add</button></div>
  <div *ngIf="showAddPurchaseForm">
    <new-purchase-form (onNewItem)=updateOnNewPurchase()></new-purchase-form>
    <button (click)="setHideAddPurchaseForm()">done</button>
  </div>
  <hr/>
  <!--form [formGroup]="filterForm">
    Filter By Manufacturer
    <select class="dropdown" forControlName="manufacturer" (change)="onManufacturerChange($event.target.value)">
      <option  value=""><div class="dropdown-item">All</div></option>
      <option [value]="m" *ngFor="let m of manufacturers"><div class="dropdown-item">{{m}}</div></option>
    </select>
  </form-->

  <div>
    <table style="width:600px">
      <tr>
        <th class="name"> Name</th>
        <th class="supplier"> Supplier</th>
        <th class="status"> Status</th>
      </tr>
      <tr *ngFor="let p of purchases" class="table-row">
          <td class="item-name"><a routerLink="/purchases/{{p.id}}" routerLinkActive='active'>{{p.name}}</a></td>
          <td class="item-supplier">{{p.supplier}}</td>
          <td class="item-creator">{{p.status}}</td>
      </tr>
    </table>
  </div>
  
  <div class="page-bar">
    <a class="pagination" *ngFor="let p of pageLinks" (click)="navigatePage(p+1)">{{p+1}}</a>
  </div>
</div>