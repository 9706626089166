import { Stocktake } from '../models/Stocktake'

export class StockListResult{
    stock: Array<StockCountDTO>;
    totalPages: number;
    page: number;
    search: string;
    message: string;
}

export class StockCountDTO {
    sku: string;
    description: string;
    image:  string;
    locations: { [locationName: string]: number };
}

export class newStocktake{
    public sku: string;
    public location: string;
    public quantity: number;
    public action: string;
}

export class QuantityAtLocationDTO{
    public quantity: number;
    public history: Array<Stocktake>;
}