<div>
  <H2>Products</H2>
  <!--img-load image="https://dfxpressstorage.blob.core.windows.net/assetsportal/product/00205801.jpg" ></img-load-->

  <div *ngIf="isLoading">
    <H2>Loading...</H2>
  </div>

  <div *ngIf="isDisplaying">

        <form [formGroup]="filterForm">
          Filter By Manufacturer
          <select class="dropdown" forControlName="manufacturer" (change)="onManufacturerChange($event.target.value)" title="Filter by manufacturer">
            <option  value=""><div class="dropdown-item">All</div></option>
            <option [value]="m" *ngFor="let m of manufacturers"><div class="dropdown-item">{{m}}</div></option>
          </select>
        </form>    

    <mat-drawer-container>
      <mat-drawer #newPopup class="popup" #newPop mode="over" opened="false" [position]="pop_position">
        <button mat-flat-button (click)="newPop.close()" type="button" class="right"><<</button>
        <hr>
        <div>
          <new-product-form (onNewItem)=updateOnNewProduct()></new-product-form>
          
        </div>
      </mat-drawer>

      <mat-drawer-content class="min-table-height">
        <button (click)="newPop.open()" mat-flat-button>+</button>


        <product-item *ngFor="let p of products" [product]="p"></product-item>
      </mat-drawer-content>

    </mat-drawer-container>

    <nav>
      <ul class="pagination">
        <li class="page-item" *ngFor="let p of pageLinks" >
          <a class="page-link" (click)="navigatePage(p+1)">{{p+1}}</a>
        </li>
      </ul>
    </nav>
  </div>

  <div *ngIf="isError">
    <H2>Failed to Load...</H2>
  </div>
</div>