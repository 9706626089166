import {AssetService} from '../../services/assets.service';
import { Component, OnInit, Output,EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NewPurchase } from '../models/NewPurchase';

@Component({
  selector: 'new-purchase-form',
  templateUrl: './new-purchase-form.component.html',
  styleUrls: ['./new-purchase-form.component.css']
})

export class NewPurchaseFormComponent implements OnInit {

  newPurchase = new NewPurchase("","","");
  @Output() onNewItem: EventEmitter<any> = new EventEmitter();

  constructor(
    private _assetService: AssetService
  ) { }

  ngOnInit() {

  }

  onSubmit(newPurchaseForm: NgForm){
    this._assetService.addPurchase(this.newPurchase)
      .subscribe(data => {
        this.newPurchase = new NewPurchase("","","");
        newPurchaseForm.reset();
        this.onNewItem.emit(null);
      });
  }

}
