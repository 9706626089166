import { Component, OnInit, Input } from '@angular/core';

import { Unit, UnitAsset } from 'app/models/Unit';
import { AssetService } from '../../../services/assets.service'
import { Asset } from 'app/models/Asset';
import { AssetUnitService } from 'services/assets.units.service';
import { UserService } from 'services/user.service';

@Component({
  selector: 'asset-assignments',
  templateUrl: './asset-assignments.html',
  styleUrls: ['./asset-assignments.css']
})
export class AssetAssignemntsComponent implements OnInit {

  @Input() assetTag: string;
  public assetAssignments: Array<UnitAsset> = [];

  constructor(
    private _assetService: AssetService,
    private _assetUnitService: AssetUnitService,
    private _user: UserService
  ) { }

  ngOnInit() { 
    this.getAssetAssignments();

  }
  getAssetAssignments() {
    this._assetService.getAssetAssignments(this.assetTag).subscribe( _assignments => {
      this.assetAssignments = _assignments;
    });
  }

  removeAsset( unit: string, assignmentid: string){
    this._assetUnitService.removeAsset(unit, assignmentid).subscribe( _unitAssets => {
      this.getAssetAssignments();
    });
  }

  get canDeleteAssignment() {return this._user.hasRole("Maintenance.Write")}
}
