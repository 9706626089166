import { Component, OnInit, Input } from '@angular/core';

import { Unit } from 'app/models/Unit';
import { AssetService } from '../../../services/assets.service'

@Component({
  selector: 'unit-item',
  templateUrl: './unit-item.component.html',
  styleUrls: ['./unit-item.component.css']
})
export class UnitItemComponent implements OnInit {

  @Input() unit: Unit;

  constructor(
    private _assetService: AssetService
  ) { }

  ngOnInit() { }
}
