import { AssetService } from '../../../services/assets.service';
import { Component, OnInit,ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder,Validators, UntypedFormControl } from '@angular/forms';
import { Asset,AssetProperty,AssetListResult } from '../../models/Asset'
import { ProductSearchComponent } from '../../product-search/product-search.component';
import { Unit, UnitListResult } from 'app/models/Unit';
import { debounceTime, distinctUntilChanged, filter, switchMap } from 'rxjs/operators';
import { error } from 'console';
import { AssetUnitService } from 'services/assets.units.service';

enum UnitPageState{
  Loading,
  Displaying,
  Error
}

@Component({
  selector: 'units',
  templateUrl: './units.component.html',
  styleUrls: ['./units.component.css']
})

export class UnitsComponent implements OnInit {

  private pageState: UnitPageState = UnitPageState.Loading;

  public units: Array<Unit> = [];
  public pageLinks: Array<number> = []
  
  public search: string = "";
  public sort: string = "";
  public page: number = 1;

  public queryField: UntypedFormControl = new UntypedFormControl();

  constructor(
    private _assetService: AssetService,
    private _assetUnitService: AssetUnitService,
    private router: Router,
    private route: ActivatedRoute){}

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if('search' in params){
        this.search = params.search;
      }
      if('sort' in params){
        this.sort = params.sort;
      }
      if('page' in params){
        this.page = params.page;
      }
    })

    this.loadUnits();

    const queryPipe = this.queryField.valueChanges
    .pipe(debounceTime(500))
    .pipe(distinctUntilChanged())

    const apiCallPipe = queryPipe
    .subscribe(query => {
      this.search = query;
      this.loadUnits();
    });
  }

  loadUnits() {
    this._assetUnitService.getUnits(this.search, this.sort, this.page).subscribe(this.readUnits);

  }

  private readUnits = {
    next: (result: UnitListResult) => {
      this.units = result.units;
      this.pageState = UnitPageState.Displaying;

      this.pageLinks = [];
      for (let i = 0; i < result.totalPages; i++) {
        this.pageLinks.push(i);
      }

      this.updateRoute();
    },
    error: (e) => {
      this.pageState = UnitPageState.Error;
    }
  };

  updateRoute( ){
    var queryParams = {};
    if (this.search != "" ){queryParams['search'] = this.search;}
    if (this.sort != "" ){queryParams['sort'] = this.sort;}
    if (this.page > 1){queryParams['page']=this.page;}
    this.router.navigate(['/units'], {queryParams: queryParams});
  }

  navigatePage(page:number){
    this.page = page;
    this.loadUnits();
  }
  
  get isLoading(){ return this.pageState == UnitPageState.Loading; }
  get isDisplaying(){ return this.pageState == UnitPageState.Displaying; }
  get isError(){ return this.pageState == UnitPageState.Error; }
}

