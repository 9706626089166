import {AssetService} from '../../../services/assets.service';
import { Component, OnInit, Output,EventEmitter, Input } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NewProduct } from '../../models/NewProduct';
import { ContractorSignin, newContractorSignin } from '../../models/ContractorSignin';
import { ActivatedRoute } from '@angular/router';
import { ServiceRequestLog } from 'app/models/ServiceRequestLog';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, isEmpty, switchMap } from 'rxjs/operators';
import { UserService } from 'services/user.service';
import { error } from 'console';
import { Unit } from 'app/models/Unit';
import { environment, sites } from 'environments/environment';
import { AssetUnitService } from 'services/assets.units.service';

@Component({
  selector: 'newUnitForm',
  templateUrl: './newUnitForm.component.html',
  styleUrls: ['./newUnitForm.component.css']
})
export class newUnitFormComponent implements OnInit {

  newUnit = new Unit();

  @Output() onNewEvent: EventEmitter<any> = new EventEmitter();
  showServiceNumberField = true;
  showSigninForm: boolean =  true;
  showSigninSucess: boolean = false;
  signinSuccessDetails: ContractorSignin;

  srValid: boolean = false;

  constructor(
    private _assetService: AssetService,
    private _assetUnitService: AssetUnitService,
    private _userService: UserService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {

  }

  getValue(event: Event): string{
    return (event.target as HTMLInputElement).value;
  }

  onSubmit(newUnitForm: NgForm){
    
    this.newUnit.parent = sites[this.newUnit.location];
    this.newUnit.domain = 'default';

    //Check that newUnit name starts with the location code and if not, add it
    if(!this.newUnit.unit.startsWith(sites[this.newUnit.location])){
      this.newUnit.unit = sites[this.newUnit.location] + "_" + this.newUnit.unit;
    }

    this._assetUnitService.addUnit(this.newUnit)
      .subscribe(data => {
        //this.signinSuccessDetails = data;

        this.newUnit = new Unit();
        newUnitForm.reset();
        this.onNewEvent.emit(null);
      });
  }
}
