<div class="container">
  <div class="image" *ngIf='product.image'>
    <img-load class="thumb" image="https://dfxpressstorage.blob.core.windows.net/assetsportal/product/{{product.image}}"></img-load>
  </div>
  <div class="image" *ngIf='!product.image'>
    <img class="thumb" src="assets/media/DefaultProductIcon.png" alt="Default Icon">
  </div>
  
  <div class="description label">
      {{product.description}}
  </div>
  <div class="identifier label">
      {{product.sku}}
    </div>
  <div class="manufacturer label">
    {{product.manufacturer}}
  </div>
</div>

