import { Component, OnInit, Input } from '@angular/core';

import { Asset } from 'app/models/Asset';
import {AssetService} from '../../services/assets.service';

@Component({
  selector: 'asset-item',
  templateUrl: './asset-item.component.html',
  styleUrls: ['./asset-item.component.css']
})
export class AssetItemComponent implements OnInit {

  @Input() asset: Asset;

  constructor(
    private _assetService: AssetService
  ) { }

  ngOnInit() { }
}
