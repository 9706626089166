<div class="container">
  <img alt="Unit Icon" class="image" src="assets/media/DefaultProductIcon.png">
  <div class="unit">
      <a routerLink="/units/{{unit.unit}}" routerLinkActive='active'>{{unit.unit}}</a>
  </div>
  <div class="description">
      {{unit.description}}
  </div>
  <div class="location">
      {{unit.location}}
    </div>
  <div class="model">
    {{unit.model}}
  </div>
</div>

