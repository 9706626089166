
<div class="container">
  <div class="number label">
    <a routerLink="/service/{{serviceRequest.number}}" routerLinkActive='active'>{{serviceRequest.number}}</a>
  </div>
  <div class="target label">
      {{serviceRequest.target}}
  </div>
  <div class="title label">
      {{serviceRequest.title}}
    </div>
  <div class="detail label">
    Created by {{serviceRequest.createdBy}} on {{(serviceRequest.createdOn + 'Z').replace(' ', 'T') | dateTimeFormatFilter: 'MMMM Do YYYY, h:mm:ss a'}}
  </div>
  <div class="close">
    <button *ngIf="serviceRequest.state=='open'" title="Button Title" (click)="closeServiceRequest()" >{{closeButtonText}}</button>
    <div *ngIf="serviceRequest.state!='open'" >{{serviceRequest.state}}</div>
  </div>
</div>
