<div *ngIf="isLoading">
  <H2>Loading...</H2>
</div>

<div *ngIf="isDisplaying">
  <serviceRequest-item *ngFor="let p of serviceRequests" [serviceRequest]="p"></serviceRequest-item>


  <nav>
    <ul class="pagination">
      <li class="page-item" *ngFor="let p of pageLinks" >
        <a class="page-link" (click)="navigatePage(p+1)">{{p+1}}</a>
      </li>
    </ul>
  </nav>
</div>

<div *ngIf="isError">
  <H2>Failed to Load...</H2>
</div>
