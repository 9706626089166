<div class="form-spacing">
  <h2>Assign stock to {{data.unit}}</h2>

  <div mat-dialog-content>
    <mat-form-field>
      <mat-label>Search Asset Tag</mat-label>
      <input matInput [formControl]="queryField" [(ngModel)]="data.stock" id="asset" placeholder="Enter product name">
    </mat-form-field>
    <ul class="filter-select">
      <li *ngFor="let product of products" (click)=onClickSelectStock(product) class="filter-select-list">
        <div>{{product.sku}}</div>
      </li>
    </ul>
  </div>

  <div *ngIf="data.stock">
    Selected product {{data.stock}}
  </div>

  <div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">Cancel</button>
    <button mat-button [disabled]="!data.stock" [mat-dialog-close]="data.stock">Ok</button>
  </div>
</div>
