import { AssetService } from '../../../services/assets.service';
import { Component, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { ServiceRequest, ServiceRequestListResult } from 'app/models/ServiceRequest';
import { ServiceRequestLog } from 'app/models/ServiceRequestLog';

@Component({
  selector: 'serviceRequestLog',
  templateUrl: './serviceRequestLog.component.html',
  styleUrls: ['./serviceRequestLog.component.css']
})

export class ServiceRequestLogComponent implements OnInit {

  @Input() srNumber: string = '';

  public serviceRequests: ServiceRequestLog[];
  
  constructor(
    private _assetService: AssetService,
  ) { }

  ngOnInit() {    
    this._assetService.getServiceRequestLog(this.srNumber).subscribe((data: ServiceRequestLog[]) => {
      this.serviceRequests = data;
    })
  }
}
