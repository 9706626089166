import { AssetService } from '../../services/assets.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, Validators} from '@angular/forms';
import { Purchase } from '../models/Purchase'
import { Product } from '../models/Product';
import { ProductSearchComponent } from '../product-search/product-search.component';

@Component({
  selector: 'purchase-detail',
  templateUrl: './purchase-detail.component.html',
  styleUrls: ['./purchase-detail.component.css']
})

export class PurchaseDetailComponent implements OnInit {

  public purchase: Purchase = new Purchase();
  public pageLinks: Array<number> = []
  public id: string;
  
  public newProduct:Product = new Product();
  public addProductForm: UntypedFormGroup = new UntypedFormGroup({});
  @ViewChild('search') search: ProductSearchComponent;

  constructor(
    private _assetService: AssetService,
    private route: ActivatedRoute,
    private fb: UntypedFormBuilder
    ){
      this.addProductForm = fb.group({
        'newQuantity' : ['', [Validators.required,Validators.min(1)]]
      })
    }

  ngOnInit() {


    this.id = this.route.snapshot.paramMap.get('id');

    this.loadPurchases(); 
  }

  loadPurchases() {
    this._assetService.getPurchase(this.id).subscribe((data: Purchase) => {
      this.purchase = data;
    })
  }

  public AddProduct(){
    
    if(this.addProductForm.valid){
      console.log("SKU result: " + this.search.selectedProduct.sku)
      

      var newProductSku:string = this.search.selectedProduct.sku;
      var newProductQuantity: number = this.addProductForm.get('newQuantity').value;

      console.log("quantity: " + newProductQuantity)
      this._assetService.addProductToPurchase(this.purchase.id, newProductSku, newProductQuantity).subscribe(() => this.loadPurchases())
    }
    else{
      console.log("Form is not valid")
    }
  }
}

